import { useState, useEffect } from 'react';
import Web3 from 'web3';
import { useLocation, useNavigate } from 'react-router-dom';
import detectEthereumProvider from '@metamask/detect-provider';
import DepositModal from './DepositModal.js';
import BalanceModal from './BalanceModal.js';
import ReactTooltip from 'react-tooltip';
import ProgressBarModal from './ProgressBarModal.js';
const config = require('./configs/tusd_abi.js');
const PORTFOLIO_ADDRESS = config.portfolioAddress;
const PORTFOLIO_ABI = config.portfolioAbi;

export default function OpenPositionDetailComponent() {
  const navigate = useNavigate();
  const [web3, setWeb3] = useState(null);
  const [perpetuals, setPerpetuals] = useState([]);
  const [matchedPerpetual, setMatchedPerpetual] = useState(null); // Renamed for clarity
  const [matchingRecord, setMatchingRecord] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [contract, setContract] = useState(null);
  const [fundingEntries, setFundingEntries] = useState([]);
  const location = useLocation();
  const [progress, setProgress] = useState(0);
  const [transactionLink, setTransactionLink] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;
  const { position, index } = location.state || {};

  const navigateBack = () => {
    navigate(-1); // Takes the user to the previous page
  };

  useEffect(() => {
    async function init() {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        window.location.href = '/sign';
        return;
      }

      const provider = await detectEthereumProvider();
      if (!provider) {
        console.error('Please install MetaMask!');
        return;
      }

      await provider.request({ method: 'eth_requestAccounts' });
      const web3Instance = new Web3(provider);
      setWeb3(web3Instance);

      const userAccounts = await web3Instance.eth.getAccounts();
      if (!userAccounts || userAccounts.length === 0) {
        console.error('No accounts available.');
        return;
      }
      setAccounts(userAccounts);

      const contractInstance = new web3Instance.eth.Contract(PORTFOLIO_ABI, PORTFOLIO_ADDRESS);
      setContract(contractInstance);
    }
    init();
  }, []);

  useEffect(() => {
    if (position && index !== undefined && contract) {
      fetchPerpetuals();
      fetchPositionDetail();
    }
  }, [position, index, contract]);

  useEffect(() => {
	if (matchingRecord) {
	  fetchFundingBuyer();
	}
  }, [matchingRecord]);

  const fetchPerpetuals = async () => {
    try {
      const fetchedPerpetuals = await contract.methods.getAllPerpetualsWithStats().call();
      
      // Assuming `position.perpetualId` is already a valid number, otherwise ensure it's converted correctly
      const perpetualIndex = parseInt(position.perpetualId, 10);

      // Check if the index exists in fetchedPerpetuals
      if (perpetualIndex >= 0 && perpetualIndex < fetchedPerpetuals.length) {
        const matchedPerpetual = fetchedPerpetuals[perpetualIndex];
        setMatchedPerpetual(matchedPerpetual);
      } else {
        console.log('No matching perpetual found for index: ', perpetualIndex);
      }

      setPerpetuals(fetchedPerpetuals);
    } catch (error) {
      console.error('Error fetching perpetuals:', error);
    }
  };

  const fetchPositionDetail = async () => {
    try {
      const userAddy = accounts[0];
      const userRecords = await contract.methods.searchRecordsByAddress(userAddy, 0).call();

      const foundRecord = userRecords.find(record => record.positionId === position.id);
      if (foundRecord) {
        setMatchingRecord(foundRecord);
		console.log(`matching record`, matchingRecord);
      }
    } catch (error) {
      console.error('Error fetching position detail:', error);
    }
  };

  const fetchFundingBuyer = async () => {
	try {
	  if (!matchingRecord) {
		console.error('No matching record found');
		return; // Exit the function early if there's no matching record
	  }
	  const userAddy = accounts[0];
	  const fundingBuyer = await contract.methods.getFundingRatesByBuyer(userAddy).call();
	  console.log(`funding rates`, fundingBuyer);
	  const entries = fundingBuyer
		.filter(record => record.positionId === matchingRecord.positionId)
		.map(record => ({
		  fundingTime: new Date(record.timestamp * 1000).toLocaleString('en-US', {
			month: '2-digit',
			day: '2-digit',
			year: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			hour12: false,
		  }),
		  fundingPrice: record.amount,
		  direction: record.direction
		}));
  
	  setFundingEntries(entries);
	} catch (error) {
	  console.error('Error fetching funding details:', error);
	}
  };



  const handleClosePerpetual = async () => {
	console.log(`matching`, matchingRecord);
	const userAddy = accounts[0];
	setIsModalOpen(true);
	setProgress(30);
	try{
	  const response = await fetch(`${config.serverURL}/api/closePosition`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			address: userAddy,
			positionId: matchingRecord.positionId
		}),
	}).catch(error => console.error("Fetch error:", error));

	const data = await response.json();
	console.log('API Response:', data);
	const transactionHash = data.hash;
	if (transactionHash) {
		const link = `https://etherscan.io/tx/${transactionHash}`;
		setTransactionLink(link);
		setProgress(100);
	}
	navigate(-1);
	} catch (error) {
		console.log('error', error);
	}
  }

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = fundingEntries.slice(indexOfFirstEntry, indexOfLastEntry);

  // Function to change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  return (
    <div className='w-full lg:w-[1290px] h-auto lg:h-[734px] relative mt-8 lg:mt-[63px] ml-4 lg:ml-[111px]'>
		<div className='flex w-full lg:w-[230px] h-auto lg:h-[656px] flex-col gap-4 lg:gap-[18px] top-0 left-0'>
			<div className="flex items-center gap-2">
				<div className="w-4 h-3 bg-cover bg-no-repeat back-icon"></div>
				<button className="text-gray-600 text-sm left-5 lg:left-20" onClick={navigateBack}>
					Back
				</button>
			</div>

			{/* Deposit Section */}
			<BalanceModal />
			<DepositModal />
		</div>

		<div className='flex flex-col gap-2 items-center w-full lg:w-[1040px] h-auto lg:h-[800px] p-2 bg-white rounded-lg border border-blue-200 absolute top-9 left-[250px] '>
			<div className='flex flex-col gap-2 bg-gray-100 rounded-md p-4 w-full'>
				<div className='flex gap-2 items-center'>
					<div className='w-4 h-3 bg-cover bg-no-repeat market-icon'></div>
					<span className='text-lg lg:text-2xl font-bold text-blue-800'>Open Position Detail</span>
				</div>
				<span className='text-xs text-gray-500'>Shows details of open position</span>
			</div>

              {/* Position Details Content */}
            {matchedPerpetual && matchingRecord ? (
            	<div className='flex pt-0 pr-6 pb-0 pl-6 flex-col gap-3 items-center self-stretch shrink-0 flex-nowrap relative '>
					<div className='flex pt-2.5 pr-0 pb-2.5 pl-0 flex-col gap-4 items-start self-stretch shrink-0 flex-nowrap relative '>
						<div className='flex w-full md:w-4/5 lg:w-full items-start shrink-0 flex-nowrap relative '>
							{/* Asset Section */}
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Asset&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal text-blue-800 relative text-left whitespace-nowrap ">
									{matchedPerpetual.perpetual.asset}
								</span>
							</div>
							{/* Name Section */}
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Name&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									{matchedPerpetual.perpetual.name}
								</span>
							</div>
							{/* Contract Address Section */}
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Contract Address&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									{`${matchedPerpetual.perpetual.perpetualAddress.slice(0, 6)}...${matchedPerpetual.perpetual.perpetualAddress.slice(-4)}`}
									{/* {`${matchedPerpetual.perpetual.perpetualAddress}`} */}
								</span>
							</div>
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Open Date&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal text-blue-800 relative text-left whitespace-nowrap ">
									{new Date(position.purchaseTime * 1000).toLocaleString('en-US', {
										month: '2-digit',
										day: '2-digit',
										year: '2-digit',
										hour: '2-digit',
										minute: '2-digit',
										second: '2-digit',
										hour12: false,
										})
									}
								</span>
							</div>
						</div>
					</div>

					{/* the line goes here */}
					<div className='h-px self-stretch shrink-0 bg-cover bg-no-repeat relative ' />

					<div className='flex pt-2.5 pr-0 pb-2.5 pl-0 flex-col gap-4 items-start self-stretch shrink-0 flex-nowrap relative '>
						<div className='flex w-full md:w-4/5 lg:w-full items-start shrink-0 flex-nowrap relative '>
							{/* Date Section */}
						
							{/* Current Price Section */}
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
							<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Position&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className={`h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal relative text-left whitespace-nowrap ${position.isLong ? 'text-[#10b981]' : 'text-[#1e3a8a]'}`}>
									 <span className={`${position.isLong ? 'long-icon' : 'short-icon'} inline-block mr-1`} aria-hidden="true"></span>
									{position.isLong ? 'Long' : 'Short'}
								</span>
							</div>
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Leverage&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									{matchingRecord.leverage}X
								</span>
							</div>
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Current Value (tUSD)&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									${parseFloat(Web3.utils.fromWei(matchingRecord.currentValuation.toString(), 'ether')).toFixed(2)}
								</span>
							</div>
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Profit/Loss (tUSD)&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" place="top" effect="solid" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									{/* ${parseFloat(Web3.utils.fromWei((matchingRecord.amountInvested - matchingRecord.currentValuation).toString(), 'ether')).toFixed(2)} */}
									{(() => {
										const investedWei = Web3.utils.toBN(matchingRecord.amountInvested.toString());
										const valuationWei = Web3.utils.toBN(matchingRecord.currentValuation.toString());
										const differenceWei = valuationWei.sub(investedWei);
										const differenceEther = Web3.utils.fromWei(differenceWei, 'ether');
										
										return parseFloat(differenceEther).toFixed(2);
									})()}

								</span>
							</div>			
						</div>
					</div>

					<div className='flex pt-2.5 pr-0 pb-2.5 pl-0 flex-col gap-4 items-start self-stretch shrink-0 flex-nowrap relative '>
						<div className='flex w-full md:w-4/5 lg:w-full items-start shrink-0 flex-nowrap relative '>
							{/* Position Section */}
							
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Current Price (tUSD)&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									${parseFloat(Web3.utils.fromWei(matchedPerpetual.perpetual.price.toString(), 'ether')).toFixed(2)}
								</span>
							</div>
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Perpetual Price at Purchased&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" /> 
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									${parseFloat(Web3.utils.fromWei(matchingRecord.record.unitCost.toString(), 'ether')).toFixed(5)}
									{/* ${parseFloat(matchingRecord.otmPriceFee.toString()).toFixed(2)} */}
								</span>
							</div>
							{/* Leverage Section */}
						
							{/* OTM Price Section */}
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
							<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Purchased Power (tUSD)&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									${parseFloat(Web3.utils.fromWei(matchingRecord.amountInvested.toString(), 'ether')).toFixed(2)}
									{/* ${parseFloat(matchingRecord.amountInvested.toString()).toFixed(2)} */}
								</span>
							</div>
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									OTM Price (tUSD)&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									${parseFloat(Web3.utils.fromWei(matchingRecord.otmPrice.toString(), 'ether')).toFixed(2)}
								</span>
							</div>
						</div>
					</div>

					<div className='flex pt-2.5 pr-0 pb-2.5 pl-0 flex-col gap-4 items-start self-stretch shrink-0 flex-nowrap relative '>
						<div className='flex w-full md:w-4/5 lg:w-full items-start shrink-0 flex-nowrap relative '>
						
							{/* Market Price Section */}
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Purchased Amount (tUSD)&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									${parseFloat(Web3.utils.fromWei(position.amount.toString(), 'ether')).toFixed(2)}
								</span>
							</div>
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Leverage Fee (tUSD)&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									${parseFloat(Web3.utils.fromWei(matchingRecord.leverageFee.toString(), 'ether')).toFixed(2)}
									{/* ${parseFloat(matchingRecord.leverageFee.toString()).toFixed(2)} */}
									
								</span>
							</div>
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									OTM Fee (tUSD)&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									${parseFloat(Web3.utils.fromWei(matchingRecord.otmPriceFee.toString(), 'ether')).toFixed(5)}
									{/* ${parseFloat(matchingRecord.otmPriceFee.toString()).toFixed(2)} */}
								</span>
							</div>
						
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative '>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
									Total Amount Purchased&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								<span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
									${parseFloat(Web3.utils.fromWei(matchingRecord.record.amount.toString(), 'ether')).toFixed(4)}
								</span>
							</div>
						</div>
					</div>

					<div className='flex pt-2.5 pr-0 pb-2.5 pl-0 flex-col gap-4 items-start self-stretch shrink-0 flex-nowrap relative'>
                        <div className='flex w-full md:w-4/5 lg:w-full items-start shrink-0 flex-nowrap relative'>
                            {/* Funding Interval Column */}
                            <div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative'>
                                <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap">
                                    Funding Interval&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
                                </span>
                                {currentEntries.length > 0 ? (
                                    currentEntries.map((entry, index) => (
                                        <span key={index} className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap">
                                            {entry.fundingTime}
                                        </span>
                                    ))
                                ) : (
                                    <span>Loading funding details...</span>
                                )}
                            </div>

                            {/* Funded Price Column */}
                            <div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative'>
                                <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap">
                                    Funded Price&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
                                </span>
                                {currentEntries.length > 0 ? (
                                    currentEntries.map((entry, index) => (
                                        <span key={index} className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap">
                                            {`$${parseFloat(Web3.utils.fromWei(entry.fundingPrice.toString(), 'ether')).toFixed(2)}`}
                                        </span>
                                    ))
                                ) : (
                                    <span>Loading funding details...</span>
                                )}
                            </div>
							
							  {/* Funded Price Column */}
							<div className='flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative'>
								<span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap">
									Acucumlated Funded Price&nbsp;
									<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
									<ReactTooltip anchorId="myButton" />
								</span>
								{fundingEntries.length > 0 ? (
									// Calculate the total funding price considering direction
									(() => {
										const totalFundingPrice = fundingEntries
											.reduce((total, entry) => {
												const fundingPrice = parseFloat(Web3.utils.fromWei(entry.fundingPrice.toString(), 'ether'));
												return entry.direction === '0' ? total + fundingPrice : total - fundingPrice;
											}, 0).toFixed(2);

										// Determine text color based on total value
										const textColor = totalFundingPrice >= 0 ? 'text-green-500' : 'text-red-500';

										return (
											<span className={`h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 ${textColor} relative text-left whitespace-nowrap`}>
												{`$${totalFundingPrice}`}
											</span>
										);
									})()
								) : (
									<span>Loading funding details...</span>
								)}
							</div>
                        </div>

                        {/* Pagination Controls */}
						<div className='flex w-full justify-between mt-4'>
                            <div className='flex'>
                                {Array.from({ length: Math.ceil(fundingEntries.length / entriesPerPage) }, (_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => paginate(index + 1)}
                                        className={`px-3 py-1 border ${currentPage === index + 1 ? 'bg-blue-600 text-white' : 'bg-white text-blue-600'}`}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                            </div>

                            <button
                                className='w-[197px] h-[44px] bg-[#ef4444] text-white rounded-[24px] flex justify-center items-center'
                                onClick={handleClosePerpetual}
                            >
                                Close
                            </button>
							<ProgressBarModal
								isOpen={isModalOpen}
								onRequestClose={() => setIsModalOpen(false)}
								progress={progress}
								transactionLink={transactionLink}
							/>
                        </div>
                    </div>
                </div>
            ) : (
                <p>Loading position details...</p>
            )}
        </div>
    </div>
);
}
