import React from "react";

export default function Timer() {
    const [displayTime, setDisplayTime] = React.useState(300); // State for displaying formatted time
    const timeLeftRef = React.useRef(300); // Ref to track time internally
    const timerRef = React.useRef(null); // Ref to store the interval reference
  
    // Function to format time in mm:ss
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };
  
    // Function to start the timer
    const startTimer = () => {
      if (!timerRef.current) {
        timerRef.current = setInterval(() => {
          if (timeLeftRef.current > 0) {
            timeLeftRef.current -= 1; // Update internal time
          } else {
            timeLeftRef.current = 300; // Reset timer to 5 minutes
          }
  
          // Update display time every second, triggers re-render only when time changes
          setDisplayTime(timeLeftRef.current);
        }, 1000);
      }
    };
  
    // Start timer on component mount
    React.useLayoutEffect(() => {
      startTimer(); // Start the timer when component mounts
  
      return () => {
        // Cleanup the interval when component unmounts
        if (timerRef.current) {
          clearInterval(timerRef.current);
          timerRef.current = null;
        }
      };
    }, []);
  
    return    <div className="funding-time flex items-center">
    <span className="time-left">{formatTime(displayTime)}</span>
    <span className="total-time"> / 5:00</span>
  </div>;
  };

  