import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import { IconButton, Tooltip, Spinner, Alert, AlertIcon, CloseButton, useToast, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import BalanceModal from './BalanceModal.js';
import DepositModal from './DepositModal.js';
import ReactTooltip from 'react-tooltip';


//import './perpetuals.css';

const config = require('./configs/tusd_abi.js');
const PORTFOLIO_ADDRESS = config.portfolioAddress;
const PORTFOLIO_ABI = config.portfolioAbi;
const { ethers } = require('ethers');

export default function Market() {
    const [perpetuals, setPerpetuals] = useState([]);
    const [longShort, setLongShort] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [web3, setWeb3] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [contract, setContract] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(50);
    const [balance, setBalance] = useState(0);
    const [amountInvested, setAmountInvested] = useState(0);
    const [currentValuation, setCurrentValuation] = useState(0);
    const toast = useToast();

    useEffect(() => {
        async function init() {
            const provider = await detectEthereumProvider();
            if (provider) {
                await provider.request({ method: 'eth_requestAccounts' });
                const web3Instance = new Web3(provider);
                setWeb3(web3Instance);
                const accounts = await web3Instance.eth.getAccounts();
                setAccounts(accounts);
                const contractInstance = new web3Instance.eth.Contract(PORTFOLIO_ABI, PORTFOLIO_ADDRESS);
                setContract(contractInstance);
                if (accounts.length > 0 && contractInstance) {
                    getAllPerpetuals(contractInstance);
                    getBalance(contractInstance, accounts[0]);
                    getPurchasesByBuyer(contractInstance, accounts[0]);
                }
            } else {
                console.error("Please install MetaMask!");
            }
        }
        init();
    }, []);
    
    const getAllPerpetuals = async (contractInstance) => {
        try {
            const perpetuals = await contractInstance.methods.getAllPerpetualsWithStats().call();
            setPerpetuals(perpetuals);
            console.log("perps", perpetuals);
            console.log("long short", longShort);

        } catch (error) {
            console.error("Error getting all perpetuals:", error);
        }
    };

    const getPurchasesByBuyer = async (contractInstance, buyer) => {
        try {
            const purchases = await contractInstance.methods.getPurchasesByBuyer(buyer).call();
            setPurchases(purchases);
        } catch (error) {
            console.error("Error getting purchases by buyer:", error);
        }
    };
    
    useEffect(() => {
        if (contract && accounts.length > 0) {
            getUserRecords();
        }
    }, [contract, accounts]);

    const getUserRecords = async () => {
        if (!contract || accounts.length === 0) {
            console.error("Contract not initialized or no accounts available.");
            return;
        }
        try {
            const userAddy = accounts[0];
            const userRecords = await contract.methods.searchRecordsByAddress(userAddy,0).call();
            const totalCurrentValuation = userRecords.reduce((total, record) => {
                const valuationInWei = BigInt(record.currentValuation || '0'); 
                return total + valuationInWei;
            }, BigInt(0));  // Initialize total as BigInt
            const formatTotalCurrentValuation = parseFloat(Web3.utils.fromWei(totalCurrentValuation.toString(), 'ether')).toFixed(2);
            setCurrentValuation(formatTotalCurrentValuation);
            console.log(`current value ${totalCurrentValuation} formated ${formatTotalCurrentValuation}`);
            setAmountInvested(userRecords.amountInvested);
            console.log('Search by Addy', userRecords);
        } catch (error) {
            console.error("Error searching records:", error);
        }
    };

    const navigate = useNavigate();

    const handleTradeClick = (perpetual, index) => {
        console.log('Navigating with data:', { perpetual, index });
        navigate('/trade', { state: { perpetual, index } });
    };

    const getBalance = async (contractInstance, account) => {
        try {
            const balance = await contractInstance.methods.getNetBalance(account).call();
            setBalance(parseFloat(Web3.utils.fromWei(balance.toString(), 'ether')).toFixed(2));
            const metaBalance = contractInstance.methods.getBalance(account);
            console.log('MetaMask Balance', metaBalance);
        } catch (error) {
            console.error("Error getting balance:", error);
        }
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = perpetuals.slice(indexOfFirstRecord, indexOfLastRecord);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='w-full lg:w-[1290px] h-auto lg:h-[734px] relative mt-8 lg:mt-[63px] ml-4 lg:ml-[111px]'>
            <div className='flex w-full lg:w-[230px] h-auto lg:h-[656px] flex-col gap-4 lg:gap-[18px] top-0 left-0'>
                <BalanceModal />
                <DepositModal />
            </div>
            
            {/* Main Content */}
		<div className='flex flex-col gap-2 items-center w-full lg:w-[1040px] h-auto lg:h-[734px] p-2 bg-white rounded-lg border border-blue-200 absolute top-0 left-[250px] '>
                <div className='flex flex-col gap-2 bg-gray-100 rounded-md p-4 w-full'>
                    <div className='flex gap-2 items-center'>
                        <div className='w-4 h-3 bg-cover bg-no-repeat market-icon'></div>
                        <span className='text-2xl font-bold text-blue-800'>Market</span>
                    </div>
                    <span className='text-xs text-gray-500'>Perpetual futures available for trade.</span>
                </div>

            {/* Trade Table */}
            <table className="w-full table-auto border-separate border-spacing-2">
                <thead className="bg-gray-200">
                <tr className="bg-gray-200">
                    <th className="text-left py-2 px-4 text-sm font-semibold text-gray-600">
                        Asset&nbsp;
                        <button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
                        <ReactTooltip anchorId="myButton" />
                    </th>
                    <th className="text-left py-2 px-4 text-sm font-semibold text-gray-600">
                        Contract&nbsp;
                        <button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
                        <ReactTooltip anchorId="myButton" />
                    </th>
                    <th className="text-left py-2 px-4 text-sm font-semibold text-gray-600">
                        Name&nbsp;
                        <button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
                        <ReactTooltip anchorId="myButton" />
                    </th>
                    <th className="text-left py-2 px-4 text-sm font-semibold text-gray-600">
                        Price (tUSD)&nbsp;
                        <button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
                        <ReactTooltip anchorId="myButton" />
                    </th>
                    <th className="text-left py-2 px-4 text-sm font-semibold text-gray-600">
                        Longs vs Shorts&nbsp;
                        <button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
                        <ReactTooltip anchorId="myButton" />
                    </th>
                </tr>
                </thead>
                <tbody>
                {currentRecords
                    .map((perpetual, index) => ({ perpetual, index }))
                    .filter(({ perpetual }) => perpetual.perpetual.status === true) 
                    .map(({ perpetual, index }) => (
                        <tr key={index} className="border-b">
                        <td className="flex items-center gap-2 py-2 px-4">
                            <span className="text-sm text-blue-800">{perpetual.perpetual.asset}</span>
                        </td>
                        <td className="py-2 px-4 text-sm text-blue-800">
                            {`${perpetual.perpetual.perpetualAddress.slice(0, 6)}...${perpetual.perpetual.perpetualAddress.slice(-4)}`}
                        </td>
                        <td className="py-2 px-4 text-sm text-blue-800">
                            {perpetual.perpetual.name}
                        </td>
                        <td className="py-2 px-4 text-sm text-blue-800">
                            ${parseFloat(Web3.utils.fromWei(perpetual.perpetual.price.toString(), 'ether')).toFixed(2)}
                        </td>
                        <td className="flex items-center justify-center py-2 px-4 gap-2">
                            <span className="text-green-600 font-bold text-sm">{perpetual.percentageHigher}%</span>
                            <span className="text-gray-600">vs</span>
                            <span className="text-red-600 font-bold text-sm">{perpetual.percentageLower}%</span>
                        </td>
                        <td className="py-2 px-4">
                            <button
                            className="bg-blue-800 text-white text-sm font-semibold py-1 px-4 rounded-lg"
                            onClick={() => handleTradeClick(perpetual, index)}
                            >
                            <span className="trade-button-icon absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"/>
                            Trade
                            </button>
                        </td>
                        </tr>
                    ))}

                </tbody>
            </table>
            
            </div>
         </div>
      
          );
}
