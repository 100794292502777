import React from 'react';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    textAlign: 'center',
    zIndex: '1000',
  },
  overlay: {
    zIndex: '1000',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

export default function ProgressBarModal({ isOpen, onRequestClose, progress, transactionLink }) {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
        contentLabel="Transaction Progress Modal"
        appElement={document.getElementById('root')}
      >
        <h2>Transaction Progress</h2>
        <div className="progress-bar-container" style={{ width: '100%', backgroundColor: '#f3f3f3', borderRadius: '5px', overflow: 'hidden', margin: '20px 0' }}>
          <div className="progress-bar" style={{ width: `${progress}%`, backgroundColor: '#4caf50', height: '10px' }}></div>
        </div>
        {transactionLink && (
          <p>
            <a href={transactionLink} target="_blank" rel="noopener noreferrer">
              View on Etherscan
            </a>
          </p>
        )}
      </Modal>
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} />
    </div>
  );
}
