import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
const config = require('./configs/tusd_abi.js');

// Injected Connector for MetaMask or similar wallets
const injected = new InjectedConnector({
  supportedChainIds: [1],
});

// WalletConnect Connector for WalletConnect compatible wallets
const walletconnect = new WalletConnectConnector({
  rpc: { 1 : `https://mainnet.infura.io/v3/${config.infuraKey}` },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 12000,
});

// WalletLink Connector for Coinbase Wallet
const walletlink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${config.infuraKey}`,
  appName: "tUSD",
  supportedChainIds: [1],
});

// Exporting the connectors
export const connectors = {
  injected: injected,
  walletConnect: walletconnect,
  walletLink: walletlink,
};
