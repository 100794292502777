import { StrictMode } from "react";
import { createRoot } from "react-dom/client"; // Updated import
import { ChakraProvider } from "@chakra-ui/react";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";

import App from "./App";
// const path = require('path');
// const dotenv = require('dotenv');

// const envPath = path.resolve(__dirname, '../.env.dev');
// dotenv.config({ path: envPath });

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

const rootElement = document.getElementById("root");

// Create a root
const root = createRoot(rootElement);  // Updated call

// Render your App into the root
root.render(
  <StrictMode>
    <ChakraProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </ChakraProvider>
  </StrictMode>
);
