import { useWeb3React } from "@web3-react/core";
import axios from 'axios';
import { useEffect, useState } from "react";
import detectEthereumProvider from '@metamask/detect-provider';
import { useLocation } from "react-router-dom";
import Web3 from 'web3';
const config = require('./configs/tusd_abi.js');
const PORTFOLIO_ADDRESS = config.portfolioAddress;
const PORTFOLIO_ABI = config.portfolioAbi;
const ethers = require('ethers');

export default function Admin() {
      const [jwtToken, setJwtToken] = useState();
      const {
        library,
        chainId,
        account,
        activate,
        deactivate,
        active
      } = useWeb3React();
	  const [web3, setWeb3] = useState(null);
	  const [accounts, setAccounts] = useState([]);
	  const [contract, setContract] = useState(null);
	  const [perpetuals, setPerpetuals] = useState([]);
	  const [editablePerpetuals, setEditablePerpetuals] = useState([...perpetuals]);

	  const [formData, setFormData] = useState({
		name: "",
		asset: "",
		price: "",
		oracleAddress: "",
		shelfLife: ""
	  });

      useEffect(() => {
          fetchJWT();
      }, []);

      const fetchJWT = () => {
          const token = localStorage.getItem("jwtToken");
          if(!token) {
              window.location.href = '/sign';
          } else {
              setJwtToken(token);
          }
      };

      useEffect(() => {
          checkIsAdmin();
      }, [account]);

      const checkIsAdmin = () => {
          // Check if either admin or account is not defined or falsy
          if (!config.admins || !account) {
              return; // Exit the function
          }

          if (config.admins.includes(account)) {
          } else {
              window.location.href = '/';
          }
      };

	  useEffect(() => {
        async function init() {
            const provider = await detectEthereumProvider();
            if (provider) {
                await provider.request({ method: 'eth_requestAccounts' });
                const web3Instance = new Web3(provider);
                setWeb3(web3Instance);
                const accounts = await web3Instance.eth.getAccounts();
                setAccounts(accounts);
                const contractInstance = new web3Instance.eth.Contract(PORTFOLIO_ABI, PORTFOLIO_ADDRESS);
                setContract(contractInstance);
				if (accounts.length > 0 && contractInstance) {
                    getAllPerpetuals(contractInstance);
                }
            } else {
                console.error("Please install MetaMask!");
            }
        }
        init();
    }, []);

	useEffect(() => {
		setEditablePerpetuals([...perpetuals]);
	  }, [perpetuals]);

	const getAllPerpetuals = async (contractInstance) => {
        try {
            const perpetuals = await contractInstance.methods.getAllPerpetuals().call();
            setPerpetuals(perpetuals);
            console.log("perps", perpetuals);
        } catch (error) {
            console.error("Error getting all perpetuals:", error);
        }
    };

	  const handleChange = (e) => {
		setFormData({
		  ...formData,
		  [e.target.name]: e.target.value
		});
	  };
	
	  const addPerpetual = async (e) => {
		e.preventDefault();
		console.log("Form Data Submitted:", formData);
		try{
			const response = await fetch(`${config.serverURL}/api/addPerpetual`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: formData.name,
                	asset: formData.asset,
                    price: formData.price,
                    address: formData.oracleAddress,
                    shelfLife: formData.shelfLife
                }),
            }).catch(error => console.error("Fetch error:", error));
            
            const data = await response.json();
            console.log('API Response:', data);
			//const addPerpetuals = await contract.methods.addPerpetual(formData.name,formData.asset, formData.price, formData.oracleAddress, formData.shelfLife).send({ from: accounts[0], to: PORTFOLIO_ADDRESS});
		} catch (error) {
            console.error("Error adding perpetuals:", error);
		}

	  };
	
	  const handleInputChange = (index, key, value) => {
		const updatedPerpetuals = editablePerpetuals.map((item, i) =>
		  i === index ? { ...item, [key]: value } : item
		);
		setEditablePerpetuals(updatedPerpetuals);
	  };
	
	  const handleUpdate = async (index) => {
		const updatedItem = editablePerpetuals[index];
		try{
      console.log('Status', updatedItem.status,);
			const response = await fetch(`${config.serverURL}/api/updatePerpetual`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    index: index,
                    name: updatedItem.name,
                    asset: updatedItem.asset,
                    price: updatedItem.price,
                    status: updatedItem.status
                }),
            }).catch(error => console.error("Fetch error:", error));
            
            const data = await response.json();
            console.log('API Response:', data);
		} catch (error) {
            console.error("Error adding perpetuals:", error);
		}
	  };
	
	  return (
		<div className="flex p-4">
		  {/* Form Section */}
		  <form
			onSubmit={addPerpetual}
			className="p-4 max-w-md bg-white shadow-md rounded"
		  >
			<h2 className="text-xl font-bold mb-4">Add Perpetuals</h2>
			<div className="mb-4">
			  <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
				Name
			  </label>
			  <input
				type="text"
				id="name"
				name="name"
				value={formData.name}
				onChange={handleChange}
				className="w-full px-3 py-2 border rounded-md"
				placeholder="Enter name"
				required
			  />
			</div>
			<div className="mb-4">
			  <label htmlFor="asset" className="block text-gray-700 font-bold mb-2">
				Asset
			  </label>
			  <input
				type="text"
				id="asset"
				name="asset"
				value={formData.asset}
				onChange={handleChange}
				className="w-full px-3 py-2 border rounded-md"
				placeholder="Enter asset"
				required
			  />
			</div>
			<div className="mb-4">
			  <label htmlFor="price" className="block text-gray-700 font-bold mb-2">
				Price
			  </label>
			  <input
				type="number"
				id="price"
				name="price"
				value={formData.price}
				onChange={handleChange}
				className="w-full px-3 py-2 border rounded-md"
				placeholder="Enter price"
				required
			  />
			</div>
	
			<div className="mb-4">
			  <label
				htmlFor="oracleAddress"
				className="block text-gray-700 font-bold mb-2"
			  >
				Oracle Address
			  </label>
			  <input
				type="text"
				id="oracleAddress"
				name="oracleAddress"
				value={formData.oracleAddress}
				onChange={handleChange}
				className="w-full px-3 py-2 border rounded-md"
				placeholder="Enter oracle address"
				required
			  />
			</div>
	
			<div className="mb-4">
			  <label
				htmlFor="shelfLife"
				className="block text-gray-700 font-bold mb-2"
			  >
				Shelf Life
			  </label>
			  <input
				type="number"
				id="shelfLife"
				name="shelfLife"
				value={formData.shelfLife}
				onChange={handleChange}
				className="w-full px-3 py-2 border rounded-md"
				placeholder="Enter shelf life"
				required
			  />
			</div>
	
			<button
			  type="submit"
			  className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-md hover:bg-blue-600"
			>
			  Submit
			</button>
		  </form>
	
		  {/* Editable Table Section */}
		  <div className="ml-6 p-4 bg-white shadow-md rounded">
        <h2 className="text-xl font-bold mb-4">Perpetuals Table</h2>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Perpetual Id</th>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Asset</th>
              <th className="px-4 py-2">Price</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {editablePerpetuals.map((item, index) => (
              <tr key={item.id || index}>
                <td className="border px-4 py-2">{index}</td>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    value={item.name}
                    onChange={(e) =>
                      handleInputChange(index, "name", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded"
                  />
                </td>
				<td className="border px-4 py-2">
                  <input
                    type="text"
                    value={item.asset}
                    onChange={(e) =>
                      handleInputChange(index, "asset", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="number"
                    value={item.price}
                    onChange={(e) =>
                      handleInputChange(index, "price", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded"
                  />
                </td>
                <td className="border px-4 py-2 text-center">
                  <input
                    type="checkbox"
                    checked={item.status}
                    onChange={(e) =>
                      handleInputChange(index, "status", e.target.checked)
                    }
                    className="h-4 w-4"
                  />
                </td>
                <td className="border px-4 py-2">
                  <button
                    onClick={() => handleUpdate(index)}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    Save
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
		</div>
	  );
}
