export const termsOfService = {
  metadata: {
    lastUpdated: "October 23, 2024",
    version: "1.0",
  },
  sections: [
    {
      id: "warning",
      type: "warning",
      title: "IMPORTANT NOTICE",
      content: "THE ONLY X INTERFACE IS NOT AVAILABLE TO PERSONS OR ENTITIES WHO RESIDE IN, ARE LOCATED IN, ARE INCORPORATED IN, OR HAVE A REGISTERED OFFICE IN THE UNITED STATES OF AMERICA OR CANADA (COLLECTIVELY, 'BLOCKED PERSONS')."
    },
    {
      id: "overview",
      type: "section",
      title: "1. Overview of Only X Services",
      content: "Only X operates as a cutting-edge financial technology platform, offering a suite of innovative trading and investment services:",
      subsections: [
        {
          title: "Perpetual Futures Exchange",
          content: "Our state-of-the-art exchange allows traders to engage in perpetual futures contracts across a range of digital assets, providing opportunities for leveraged trading and hedging."
        },
        {
          title: "Gold Trading Exchange",
          content: "We facilitate the trading of gold-backed digital assets, bridging the gap between traditional precious metals markets and the world of cryptocurrency."
        }
      ]
    },
    {
      id: "account-opening",
      type: "section",
      title: "2. Account Opening; Information Requirements; Recordkeeping",
      content: "",
      subsections: [
        {
          id: "account-opening-requirements",
          title: "2.1. Account Opening",
          content: "No Licensee of Only X LLC shall become a Customer of Only X LLC and access its services without: (i) completing an application by, among other things, providing all such information as Only X LLC may require, in its sole discretion, in order to comply with all Applicable Laws..."
        }
      ]
    },
    {
      id: "risk-disclosures",
      type: "section",
      title: "12. Risk Disclosures",
      content: "",
      subsections: [
        {
          title: "12.1",
          content: "Virtual currency is not legal tender, is not backed by the government, and accounts and value balances are not subject to Federal Deposit Insurance Corporation or Securities Investor Protection Corporation protections."
        },
        {
          title: "12.2",
          content: "Legislative and regulatory changes or actions at the state, federal, or international level may adversely affect the use, transfer, exchange, and value of virtual currency."
        }
      ]
    },
    {
      id: "definitions",
      type: "section",
      title: "1. Definitions",
      content: "Capitalized terms used herein this Appendix C shall have the following meaning:",
      subsections: [
        {
          id: "def-account",
          title: "1.1. Account",
          content: "means your account relationship with Only X LLC, which shall include the ability to access and utilize the Only X LLC Services."
        },
        {
          id: "def-applicable-laws",
          title: "1.2. Applicable Laws",
          content: "means applicable laws, statutes, regulations, rules, ordinances and self-regulatory guidelines."
        },
        {
          id: "def-bsa-aml-ofac",
          title: "1.3. BSA/AML/OFAC Requirements",
          content: "has the meaning set out in Section 16."
        },
        {
          id: "def-customer",
          title: "1.4. Customer",
          content: "means a Licensee, including its affiliates, authorized persons, signatories and employees, who becomes a customer of the Only X LLC Services."
        },
        {
          id: "def-eligible-digital-assets",
          title: "1.5. Eligible Digital Assets",
          content: "means any convertible virtual currency or other digital asset approved by and/or supported by Only X LLC for use in connection with the Only X LLC Services."
        },
        {
          id: "def-external-account",
          title: "1.6. External Account",
          content: "has the meaning set forth in Section 6.1."
        },
        {
          id: "def-fiat-currency",
          title: "1.7. Fiat Currency or Fiat",
          content: "means U.S. dollars and any other government-backed fiat currencies approved by and/or supported by Only X LLC for use in connection with the Only X LLC Services."
        },
        {
          id: "def-fincen",
          title: "1.8. FinCEN",
          content: "means the Financial Crimes Enforcement Network, a department of the United States Treasury."
        },
        {
          id: "def-onlyx-llc",
          title: "1.9. Only X LLC",
          content: "means Only X LLC, a Delaware limited liability company that is wholly owned by Only X, Inc., and operates as a registered 'money services business' and licensed 'money transmitter' (or its equivalent), if applicable, in one or more U.S. jurisdictions."
        },
        {
          id: "def-onlyx-services",
          title: "1.10. Only X LLC Services",
          content: "has the meaning set forth in Section 5."
        },
        {
          id: "def-software-ui",
          title: "1.11. Only X Software UI",
          content: "means the standard user interface used by Licensee to access and administer their assets and through the Software Service."
        },
        {
          id: "def-force-majeure",
          title: "1.12. Force Majeure Event",
          content: "has the meaning set out in Section 15.10."
        },
        {
          id: "def-initial-term",
          title: "1.13. Initial Term",
          content: "has the meaning set out in Section 11."
        },
        {
          id: "def-license-agreement",
          title: "1.14. License Agreement",
          content: "means the agreement entered into by and between Licensee and Only X, LLC. conferring a limited license to access and use the Software Service."
        },
        {
          id: "def-licensee",
          title: "1.15. Licensee",
          content: "means a customer in good standing of the Software Service provided Only X LLC. pursuant to the License Agreement."
        },
        {
          id: "def-ofac",
          title: "1.16. OFAC",
          content: "means the Office of Foreign Assets Control, a department of the United States Treasury."
        },
        {
          id: "def-order",
          title: "1.17. Order",
          content: "has the meaning given to it in the License Agreement."
        },
        {
          id: "def-prohibited-person",
          title: "1.18. Prohibited Person",
          content: "means (a) any individual or entity subject to any sanctions administered or enforced by the United States, including the SDN List and Sectoral Sanctions Identifications List maintained by the U.S. Department of the Treasury's Office of Foreign Assets Control; and (b) any individual or entity located, organized, or resident in Cuba, Iran, North Korea, Syria, or the Crimea Region of Ukraine, or owned or controlled by any individual, entity or government in those countries or regions."
        },
        {
          id: "def-software-service",
          title: "1.19. Software Service",
          content: "means the software and associated services governed by a License Agreement with Only X LLC."
        },
        {
          id: "def-terms",
          title: "1.20. Terms",
          content: "means these terms and conditions governing your use of the Only X LLC Service."
        }
      ]
    },
    {
      id: "overview",
      type: "section",
      title: "2. Overview of Only X LLC Service",
      content: "Only X operates as a cutting-edge financial technology platform, offering a suite of innovative trading and investment services:",
      subsections: [
        {
          id: "perpetual-futures",
          title: "1. Perpetual Futures Exchange",
          content: "Our state-of-the-art exchange allows traders to engage in perpetual futures contracts across a range of digital assets, providing opportunities for leveraged trading and hedging."
        },
        {
          id: "gold-trading",
          title: "2. Gold Trading Exchange",
          content: "We facilitate the trading of gold-backed digital assets, bridging the gap between traditional precious metals markets and the world of cryptocurrency."
        },
        {
          id: "tgold-token",
          title: "3. tGold Token",
          content: "Only X mints tGold, a gold-backed stablecoin that offers the stability of gold with the flexibility and efficiency of blockchain technology."
        },
        {
          id: "trusted-usdt",
          title: "4. Trusted USDT Stablecoin",
          content: "We issue a trusted USDT stablecoin, providing a reliable digital representation of the US dollar for trading and value storage within our ecosystem."
        },
        {
          id: "platform-summary",
          type: "note",
          content: "Through these services, Only X aims to provide a comprehensive platform for both traditional and crypto traders, combining the security of gold-backed assets with the dynamism of digital currency markets."
        }
      ]
    },
    {
      id: "account-opening",
      type: "section",
      title: "3. Account Opening; Information Requirements; Recordkeeping",
      content: "",
      subsections: [
        {
          id: "account-opening-requirements",
          title: "3.1. Account Opening",
          content: "No Licensee of Only X LLC shall become a Customer of Only X LLC and access its services without: (i) completing an application by, among other things, providing all such information as Only X LLC may require, in its sole discretion, in order to comply with all Applicable Laws, including its BSA/AML/OFAC Requirements (as defined herein). And, if such application is approved by Only X LLC's compliance department, (ii) agreeing to comply with these Terms. Only X LLC, in its sole discretion, may reject any application and decline to establish an Account to any otherwise eligible Licensee for any reason without notice or explanation."
        },
        {
          id: "false-information",
          title: "3.2. False Information",
          content: "You understand and acknowledge that submitting false information in connection with an application, or failure to timely update us with respect to material changes to your application information, may expose Only X LLC to liability. You understand further that your willful or negligent submission of false information at account opening, or any time after becoming a Customer, may result in costs to Only X LLC, including, but not limited to, the costs of defending against legal or regulatory actions brought by competent authorities, or civil and/or criminal monetary penalties. To the fullest extent of the law, you agree that such costs as are demonstrably and directly incurred by Only X LLC as a result of your willful or negligent submission of false or misleading information at account opening or at any time after becoming a Customer of Only X LLC are an indemnifiable liability under these Terms."
        },
        {
          id: "recordkeeping",
          title: "3.3. Recordkeeping; Handling of Customer Account Information",
          content: "Only X LLC is required by Applicable Law, including its BSA/AML/OFAC Requirements, to keep and retain the information Customer provides Only X LLC regarding the identity of the legal entity applicant and its related persons during onboarding as well as information regarding Customer's use of Only X LLC Service on an ongoing basis. Only X LLC will keep and maintain such records of such information for a minimum of five (5) years, or such longer period as it deems appropriate in its sole discretion. Such Customer information shall be deemed 'Confidential Information' as that term is defined in Section 12 of the License Agreement and held in confidence, except as Only X LLC may, in its sole discretion, determine the information is required to be disclosed or released in order to comply with its regulatory Requirements. Customer information shall be kept and maintained in a form and manner consistent with Only X LLC's policies and obligations under Applicable Law."
        }
      ]
    },
    {
      id: "account-information",
      type: "section",
      title: "4. Account Information; Discrepancies",
      content: "",
      subsections: [
        {
          id: "account-info-access",
          title: "4.1. Account Information",
          content: "Customers may obtain Account information, including information regarding the balances associated with the Account (if any), detailed information regarding transactions and transaction history, and pending and posted transfers, at any time and at no cost through the Only X Service UI. You may also obtain this information at any time and at no cost by contacting the Only X Customer Success Team through the Only X Service UI. Account information can also be obtained by emailing the Only X Service Customer Success Team or writing Only X LLC at 'AttnXXXXXXXXXXXXXXXXXXXXX.' We will not provide paper statements in connection with the Only X LLC Service, except as may be required by Applicable Law. If required to provide a paper statement, then Only X LLC may charge you any and all fees associated with the production of the paper statement allowed under law."
        },
        {
          id: "discrepancies",
          title: "4.2. Discrepancies",
          content: "Customers are responsible for promptly examining this information and notifying Only X LLC of any potential errors or discrepancies. ONLY X LLC WILL NOT BE RESPONSIBLE FOR ERRORS THE EXISTENCE OF WHICH WERE NOT PROMPTLY NOTIFIED TO US WITHIN THIRTY (30) DAYS AFTER THE DATE THE ACCOUNT STATEMENT WAS ISSUED.",
          type: "warning"
        }
      ]
    },
    {
      id: "onlyx-service",
      type: "section",
      title: "5. Only X Service",
      content: "Only X LLC offers the following services to those Licensees who have successfully completed the Only X LLC onboarding process and opened an Account (the 'Only X LLC Services'). All Only X LLC Services described in this Section 5 shall be accessible to Customers through the Software Service UI, unless otherwise specified.",
      subsections: [
        {
          id: "fiat-currency-services",
          title: "5.1. Fiat Currency Services",
          content: "Only X LLC may, upon request, accept, hold and manage deposits of Fiat Currency on behalf of Customers and make them available for use in connection with Only X LLC's other services.",
          subsections: [
            {
              id: "accepted-fiat",
              title: "(a) Accepted Fiat currencies",
              content: "Only X LLC currently accepts U.S. dollars for use in connection with its services. Additional Fiat Currencies may be approved at a later date, in the sole discretion of Only X LLC, and notified to Customers at such time."
            },
            {
              id: "fiat-management",
              title: "(b) Management of Fiat Currencies Deposited with Only X LLC",
              content: "Fiat Currency that is deposited with Only X LLC will be held, pooled and managed as bank deposits and/or high-quality liquid assets and, in any event, at all, in a manner that is at all times in accordance with applicable state law. Only X LLC shall own the interest and any other earnings from these investments to the extent permitted by law, regulation and this Terms. Notwithstanding the foregoing, the principal of all amounts of Fiat Currency held on behalf of Customers by Only X LLC will be held apart from the corporate funds of Only X LLC and its affiliates, and under no circumstances shall these funds be made available for any corporate purpose, nor to creditors in insolvency proceedings."
            }
          ]
        },
        {
          id: "purchasing-sale-services",
          title: "5.2. Purchasing and Sale Services",
          content: "Only X LLC may directly facilitate Customer purchase and sale transactions in Eligible Digital Assets in exchange for Customer Digital Asset and/or Fiat Currencies. In addition, Only X LLC can facilitate purchases, sales and other transfers of value involving Digital Assets and/or Fiat Currencies, including stablecoin conversations and certain applications of Eligible Digital Assets, such as facilitating transfers of assets to and from 'DeFi' applications and other services.\n\nOnly X LLC's purchasing and settlement services are available for use in connection with Fiat Currencies accounts at third-party financial institutions connected to the Only X network via API as well as with Fiat Currency deposits held by Only X LLC directly on behalf of its Customers. Customer Accounts at Only X LLC are also fully integrated for use with Licensee's Digital Asset Vaults."
        },
        {
          id: "offplatform-services",
          title: "5.3. Off-Platform Payment Escrow / Settlement Services",
          content: "Only X LLC may facilitate Customer transactions in Eligible Digital Assets and/or Fiat Currencies executed directly by Customer off-platform by settling one or both legs of such off-platform transaction on behalf of the Customer."
        },
        {
          id: "compliance-services",
          title: "5.4. Compliance Services",
          content: "Only X LLC may, from time to time in its sole discretion, make available to its Customers certain services designed to facilitate the Customer's direct requirements with respect to compliance with law or regulation and/or to facilitate compliance with such requirements for Customer's counterparty. Such services may include opening and maintaining a BSA/AML-compliant Vault for Customers to serve as an on- or off-ramp to facilitate certain transactions, including DeFi applications and other uses."
        },
        {
          id: "tgold-services",
          title: "5.5. tGOLD Services",
          content: "Only X LLC offers services related to tGOLD, our gold-backed stablecoin. These services include, but are not limited to, minting, redeeming, and trading tGOLD tokens. The value of tGOLD is tied to the price of physical gold, and Only X LLC maintains relationships with miners and other reserves of physical gold to back the tGOLD tokens in circulation."
        }
      ]
    },
    {
      id: "account-funds",
      type: "section",
      title: "6. Account Funds; Deposits and Withdrawals; Cancellations",
      content: "",
      subsections: [
        {
          id: "external-accounts",
          title: "6.1. External Accounts",
          content: "A Customer may link an external account to their Account for the purposes of making Fiat Currency or cryptocurrency deposits to, and withdrawals from, their Only X LLC 'for the benefit' of Customer Fiat and cryptocurrency account. Customer may also link an external account for the purposes of utilizing Only X LLC's purchasing/sale and off-platform escrow/settlement services. In any case, Only X LLC must expressly approve of each External Account that is proposed to be linked to an Account for the purpose of making deposits or withdrawals in accordance with these Terms. Only X LLC may rescind its approval of an External Account at any time. Only X LLC will only process withdrawal transactions to External Accounts that have previously been approved by Only X LLC as sources of funds."
        },
        {
          id: "fiat-deposits-withdrawals",
          title: "6.2. Fiat Currency Deposits and Withdrawals",
          content: "Only X LLC accepts: (i) wire deposits and withdrawals and (ii) automated clearing house ('ACH') deposits and withdrawals for Fiat Currencies from External Accounts. Customer authorizes Only X to credit the relevant Account with all deposits that are received in accordance with these Terms and Applicable Law. Settlement times may vary for deposits and withdrawals depending on a number of factors, including, but not limited to, the internal processes of the settling banks, the jurisdiction in which they are located, and the occurrence of bank holidays. Customer acknowledges and accepts that the timing associated with a deposit or withdrawal depends in part upon the performance of third-party intermediaries, and Only X LLC makes no guarantee and undertakes no responsibility regarding the amount of time it may take in connection with any deposit or withdrawal transaction from or to an External Account. Only X LLC reserves the right to deny or reject fiat transactions, or freeze fiat accounts in accordance with state and federal laws, in order to conduct security reviews as needed, and or in violation of Only X LLC's policies."
        },
        {
          id: "fiat-insurance",
          title: "6.3. Fiat Insurance",
          content: "Fiat deposits with Only X LLC are not insured by any government-sponsored or non-government sponsored insurance scheme or product in the United States or outside of it. Notwithstanding the foregoing, U.S. dollar deposits in 'for the benefit of' Customers are held in accounts with U.S.-based financial institutions and may be eligible for 'pass-through' deposit insurance through the Federal Deposit Insurance Corporation ('FDIC'), subject to the standard maximum insurance amount of $250,000. Only X LLC's policy is to comply, in good faith, with regulations and other requirements of the FDIC for pass-through deposit insurance, including those contained in 12 C.F.R. § 330. The availability of FDIC insurance may be limited by a number of factors, not all of them within the control of Only X."
        },
        {
          id: "digital-asset-deposits",
          title: "6.4. Digital Asset Deposits and Withdrawals",
          content: "Only X LLC will accept Digital Assets transferred to one or more Only X Vault(s) designated for use with the Only X LLC Services. Deposits and withdrawals from any Only X Vault designated for use with the Only X LLC Services will typically be credited to, or debited from, your Account after the required number of network confirmations have occurred on the blockchain, or other relevant settlement metric, which shall be determined for each Digital Asset accepted on the Only X LLC Service in Only X LLC's sole discretion."
        }
      ]
    },
    {
      id: "customer-obligations",
      type: "section",
      title: "7. Obligations of Customers; Affirmative Covenants",
      content: "As a Customer of Only X LLC, you agree that you are responsible for all obligations and liabilities you incur through your use of the Only X LLC Service, whether such obligations are due to counterparties, other third parties, or to Only X LLC. In addition, you also agree to accept responsibility for your conduct and use of the Only X LLC Service, compliance with Applicable Law, and any other stipulations as Only X LLC sees fit to impose in order to ensure its customers uphold the integrity and reputation of Only X LLC and its affiliates. For the avoidance of doubt, Customer affirmatively agrees to each of the following stipulations in connection with its use of the Only X LLC Service:",
      subsections: [
        {
          id: "compliance-obligation",
          title: "7.1.",
          content: "You will comply in all material respects with (i) these Terms, (ii) the instructions given by Only X LLC regarding your Account, and (iii) all Applicable Laws regarding your use of the Account and the Only X LLC Service."
        },
        {
          id: "information-provision",
          title: "7.2.",
          content: "Customer agrees to promptly provide Only X LLC with such information and documents as requested in connection with its use of the Only X LLC Services to the fullest extent necessary for Only X LLC to determine its compliance with its BSA/AML/OFAC Requirements and Applicable Law. Customer agrees to provide all such information in such manner and format as may be reasonably requested by Only X LLC."
        },
        {
          id: "material-changes",
          title: "7.3.",
          content: "Customer agrees to notify Only X LLC of the occurrence of any material change to any of the information previously submitted to Only X LLC in connection with any provision of these Terms and shall furnish such additional information and statements as may be reasonably necessary for Only X LLC, in its sole discretion, to reevaluate Customer's continued eligibility for the Only X LLC Services."
        },
        {
          id: "breach-notification",
          title: "7.4.",
          content: "Customer agrees to promptly provide Only X LLC with written notice of any actual or suspected breach of these Terms, or any event, circumstance or development that could reasonably be expected to materially impair Customer's inability to comply with these Terms."
        },
        {
          id: "compliance-steps",
          title: "7.5.",
          content: "Customer agrees take all reasonable steps to be in material compliance with Applicable Law and will not use, or permit the use of, any of the Only X LLC Services in any manner that would (i) violate any Applicable Laws, (ii) breach any contract, intellectual property right or other third party right to which it is a party or subject, (iii) adversely affect or interfere with the ability of Only X LLC to provide any Service to any of its customers, (iv) breach any of these Terms."
        },
        {
          id: "account-restrictions",
          title: "7.6.",
          content: "Customer agrees that Only X LLC may, with or without prior notice to Customer, lock one or more of the Only X LLC Accounts, including any of the assets of Customer in Only X LLC's possession or control. Customer also acknowledges and agrees that Only X LLC may reject or prohibit transfers of any kind, decline a deposit or withdrawal request, and/or segregate Customer assets or property if Only X LLC determines, suspects, or is advised that such actions should be taken to comply with any of its BSA/AML/OFAC Requirements or other Applicable Laws in any relevant jurisdiction. Customer acknowledges that Exchange may be required to report transactions that raise suspicions of money laundering or OFAC violations and to disclose the identity of Customer and any related parties to appropriate government authorities."
        }
      ]
    },
    {
      id: "representations-warranties",
      type: "section",
      title: "8. Customer Representations and Warranties",
      content: "On the date that Customer becomes a Customer and on each day thereafter that Customer accesses the Only X LLC Services, Customer represents and warrants that:",
      subsections: [
        {
          id: "prohibited-person",
          title: "8.1.",
          content: "Customer is not, and shall not become during the Term, a Prohibited Person. Customer shall not transact on, or otherwise interact with, the Only X LLC Services on behalf of or for the financial benefit of a Prohibited Person. Customer is not owned or controlled by any Prohibited Person."
        },
        {
          id: "prohibited-jurisdiction",
          title: "8.2.",
          content: "Customer is not, and shall not become during the Term, a resident of a Prohibited Jurisdiction."
        },
        {
          id: "accurate-information",
          title: "8.3.",
          content: "No document furnished in connection with Customer's application to become a Customer of Only X LLC or as Only X may request from time to time thereafter contains any untrue statement of a material fact, or omits to state a material fact necessary in order to make the statements contained in such furnished material, in light of the circumstances under which they were made, not misleading. To the best knowledge of Customer, there is no fact regarding the Customer's business, reputation, financial condition, pending regulatory or legal action, or operations that, if disclosed, could reasonably be anticipated to materially and adversely alter Only X LLC's evaluation of Customer's suitability to be a Customer."
        },
        {
          id: "risk-acknowledgment",
          title: "8.4.",
          content: "Customer is sophisticated and fully familiar with the inherent risks involved in the arrangements and transactions contemplated in these Terms, including the risks of new financial regulatory requirements, potential loss of money and risks due to volatility of the price. Customer understands and voluntarily takes full responsibility for the risks associated with the activity contemplated in these Terms."
        },
        {
          id: "no-advice",
          title: "8.5.",
          content: "Only X LLC has not offered, and Customer has not relied on, any legal or financial advice of Only X LLC or its affiliates or representatives, or any statements that could be construed as such advice, in seeking to enter into these Terms."
        },
        {
          id: "business-purpose",
          title: "8.6.",
          content: "Customer confirms that it is entering into these Terms wholly or predominantly for the purposes of operating the business it has represented to Only X LLC that it carries on."
        },
        {
          id: "legal-advice",
          title: "8.7.",
          content: "Customer has taken independent legal advice regarding the effect of the provisions of these Terms."
        },
        {
          id: "no-breach",
          title: "8.8.",
          content: "Customer has not breached any of the terms or provisions of the License Agreement, and each of the representations and warranties made by Customer in or pursuant to that agreement is true and correct except as may be qualified in such agreement. No material breach by Customer under this Agreement or the License Agreement has occurred or exists, and none shall be caused by the performance by Customer of its obligations under this Agreement."
        }
      ]
    },
    {
      id: "remedies",
      type: "section",
      title: "9. Remedies",
      subsections: [
        {
          id: "remedies-termination",
          title: "9.1.",
          content: "Only X LLC may cease providing services and terminate the Account immediately upon a determination by Only X LLC in its sole discretion that Customer is: (i) in violation of any of its covenants, representations or warranties under these Terms; or, (ii) no longer a Licensee of Only X, Inc. in good standing."
        }
      ]
    },
    {
      id: "fees",
      type: "section",
      title: "10. Fees",
      subsections: [
        {
          id: "fee-structure",
          title: "10.1.",
          content: "Your Account is subject to the fees described in this Terms and as further detailed in any applicable Order. In general, Account fees are assessed: (i) as a flat fee or other trading fees incurred in connection with Account opening; and (ii) on a per transaction basis. Notwithstanding the foregoing, Only X LLC reserves the right to charge additional fees for its services, or to assess fees differently than described herein this Section 10.1., and, in any event, as described in the commercial terms agreed to by and between Only X LLC and its Customer and memorialized in any applicable Order. By using the Only X LLC Services, you agree that any fees expressed in an Order related to your use of the Only X Service constitute a binding commitment and condition of your use of the Only X LLC Service. To the extent there is any discrepancy between these Terms and the then applicable Order, the terms and conditions of the Order shall prevail."
        },
        {
          id: "fee-payment",
          title: "10.2.",
          content: "Fees assessed in connection with Customer's use of the Only X LLC Services shall be payable in addition to any fees owed in connection with Licensee's use of the Service pursuant to any License Agreement. Customer agrees to pay all such fees owed pursuant to these Terms and the License Agreement in a timely manner in accordance with the payment terms of the License Agreement or any applicable Order."
        },
        {
          id: "fee-deduction",
          title: "10.3.",
          content: "Customer hereby authorizes and directs Only X LLC to deduct, if necessary, all fees due and payable from your Account (whether in the form of Fiat Currency or Digital Assets) to the fullest extent permitted by Applicable Law. If you close your account before paying all of the fees you have incurred, you acknowledge and agree that Only X LLC shall have the right to set-off such amounts as are reasonably owed to Only X LLC and deduct that amount from your Account assets before returning them to you. If there are not enough funds in your Account to cover the amounts you owe to us, we may deduct up to the full remaining amount and close your Account without being liable to you."
        }
      ]
    },
    {
      id: "taxes",
      type: "section",
      title: "11. Taxes",
      content: "Customer is responsible for payment of all taxable amounts, including sales tax, value added tax, withholding taxes, export, import and other duties imposed by any governmental agency in connection with its use of the Only X LLC Services. All items of income, gain, expense and loss that Only X LLC is required to report shall be reported by Only X LLC to the relevant federal, state and local taxing authorities under the name and taxpayer identification number of Customer. All amounts payable to Only X LLC hereunder shall not be subject to any set-off or deduction. All fees are exclusive of any applicable taxes, duties and similar governmental charges, and, except with respect to income taxes of Only X LLC, Customer agrees to hold Only X LLC harmless from all claims and liability arising from Customer's failure to report or pay such taxes, duties or other governmental charges."
    },
    {
      id: "risk-disclosures",
      type: "section",
      title: "12. Risk Disclosures",
      subsections: [
        {
          id: "risk-not-legal-tender",
          title: "12.1.",
          content: "Virtual currency is not legal tender, is not backed by the government, and accounts and value balances are not subject to Federal Deposit Insurance Corporation or Securities Investor Protection Corporation protections."
        },
        {
          id: "risk-regulatory",
          title: "12.2.",
          content: "Legislative and regulatory changes or actions at the state, federal, or international level may adversely affect the use, transfer, exchange, and value of virtual currency."
        },
        {
          id: "risk-irreversible",
          title: "12.3.",
          content: "Transactions in virtual currency may be irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable."
        },
        {
          id: "risk-timing",
          title: "12.4.",
          content: "Some virtual currency transactions shall be deemed to be made when recorded on a public ledger, which is not necessarily the date or time that the customer initiates the transaction."
        },
        {
          id: "risk-value",
          title: "12.5.",
          content: "The value of virtual currency may be derived from the continued willingness of market participants to exchange fiat currency for virtual currency, which may result in the potential for permanent and total loss of value of a particular virtual currency should the market for that virtual currency disappear."
        },
        {
          id: "risk-acceptance",
          title: "12.6.",
          content: "There is no assurance that a person who accepts a virtual currency as payment today will continue to do so in the future."
        },
        {
          id: "risk-volatility",
          title: "12.7.",
          content: "The volatility and unpredictability of the price of virtual currency relative to fiat currency may result in significant loss or tax liability over a short period of time."
        },
        {
          id: "risk-security",
          title: "12.8.",
          content: "The nature of virtual currency may lead to an increased risk of fraud or cyber attack."
        },
        {
          id: "risk-tech",
          title: "12.9.",
          content: "The nature of virtual currency means that any technological difficulties experienced by Only X LLC may prevent the access or use of a customer's virtual currency."
        },
        {
          id: "risk-trust",
          title: "12.10.",
          content: "Any bond or trust account maintained by Only X LLC for the benefit of its customers may not be sufficient to cover all losses incurred by customers."
        },
        {
          id: "risk-tgold",
          title: "12.11.",
          type: "list",
          content: "Specific risks related to tGOLD:",
          items: [
            "a. The value of tGOLD is tied to the price of physical gold, which can be volatile and subject to various market factors.",
            "b. While tGOLD is backed by physical gold reserves or gold mines, there may be risks associated with the custody and security of these reserves.",
            "c. Regulatory changes specific to gold-backed tokens could impact the operation and value of tGOLD."
          ]
        },
        {
          id: "risk-acknowledgment",
          title: "",
          type: "warning",
          content: "YOU ACKNOWLEDGE AND ACCEPT THE RISKS OF BUYING, SELLING, HOLDING AND INVESTING IN DIGITAL ASSETS AND AGREE THAT ONLY X LLC IS NOT LIABLE FOR ANY LOSS OF VALUE YOU MAY EXPERIENCE AS A RESULT OF CHOOSING TO ACCESS ANY OF THE ONLY X LLC SERVICES."
        }
      ]
    },
    {
      id: "no-investment-advice",
      type: "section",
      title: "13. No Investment Advice or Brokerage",
      content: "For the avoidance of doubt, Only X LLC does not provide investment, tax, or legal advice, nor does Only X LLC act as a broker to trades. All orders placed through Only X LLC are executed based on the parameters of the order instructions and in accordance with our standard buy/sell procedures. Only X LLC Customers are solely responsible for determining whether any investment, investment strategy or related transaction is appropriate based on its financial condition, sophistication and risk tolerance. Customers should consult their own legal or tax professionals before accessing Only X LLC Services. Only X LLC or its affiliates may assist users in learning more about the Only X LLC Services or the Software Service. Such information may be delivered in the form of blog posts, articles, links to third-party content, news feeds, tutorials, videos and consultations. None of the information provided in this context constitutes investment advice, financial advice, trading advice, or any other sort of advice. Customer acknowledges that such information does not constitute advice and represents that it shall not make decisions in reliance on any such information. Customer acknowledges that, before making the decision to buy, sell or hold any digital assets, it should conduct its own diligence and consult and its own legal and financial advisors. Only X LLC will not be held responsible for any decision its Customers make to buy, sell, hold or invest in digital assets."
    },
    {
      id: "account-closure",
      type: "section",
      title: "14. Account Closure",
      subsections: [
        {
          id: "closure-effect",
          title: "14.1. Effect of Account Closure; Return of Funds",
          content: "Upon request of a Customer, or upon closure of the Account for any reason, Only X LLC will return any and all unencumbered (i.e., not locked, blocked or otherwise encumbered) Fiat and Digital Assets held by Only X LLC in accounts managed on the Customer's behalf (if any) to such Customer in accordance with the standard withdrawal policies of Only X LLC. If Customer intends and is permitted to remain a Licensee of the Software Service, Customer may elect to designate one or more Only X Vaults as the destination for the return of Customer's Digital Assets held with Only X LLC."
        },
        {
          id: "closure-license",
          title: "14.2. Relationship to License Agreement",
          content: "Only X LLC Account closure shall not itself constitute a breach or default of the License Agreement attributable to the Licensee. Following Account closure of these Terms, the License Agreement shall continue in effect unless terminated in accordance with Section 13; provided, however, that account closure pursuant to the exercise of Only X LLC's remedies (Section 9) may also constitute a material breach of the License Agreement."
        },
        {
          id: "unclaimed-property",
          title: "14.3. Unclaimed Property",
          content: "If for any reason Only X LLC is unable to return assets to Customer's designated External Account after a statutory period of inactivity on the Only X LLC Service, then Only X LLC will report and remit such assets in accordance with applicable unclaimed property or escheatment laws of the relevant U.S. state, as determined by Only X LLC in its sole discretion."
        }
      ]
    },
    {
      id: "disclaimer-warranties",
      type: "section",
      title: "15. Disclaimer of Warranties",
      subsections: [
        {
          id: "warranty-disclaimer",
          title: "15.1.",
          content: "TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, AND EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY ONLY X LLC, (A) THE ONLY X LLC SERVICE, INCLUDING THE INFORMATION, CONTENT, DATA AND MATERIALS CONTAINED THEREIN, ARE PROVIDED ON AN 'AS IS' AND 'AS AVAILABLE' BASIS; AND (B) ONLY X LLC EXPRESSLY DISCLAIMS, AND CUSTOMER WAVES, ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT AS TO THE SERVICE.",
          type: "warning"
        },
        {
          id: "warranty-disclaimer-2",
          title: "15.2.",
          content: "ONLY X LLC DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES (A) THAT THE ONLY X LLC SERVICES WILL BE UNINTERRUPTED, ERROR-FREE OR SECURE; OR (B) REGARDING THE QUALITY, ACCURACY OR COMPLETENESS OF ANY DATA, MATERIALS OR SERVICES PROVIDED BY OR IN CONNECTION WITH ANY THIRD PARTY.",
          type: "warning"
        }
      ]
    },
    {
      id: "indemnification",
      type: "section",
      title: "16. Indemnification",
      content: "By using the Only X LLC Services, Customer agrees to indemnify and hold harmless, and upon written request of Only X LLC, to defend, Only X LLC, its affiliates and each of their respective shareholders, members, officers, directors, managers, employees, attorneys and agents (each an 'Indemnified Party') from and against any and all liabilities, obligations, losses, damages, penalties, judgments, claims, costs, expenses and disbursements of any kind or nature (including the disbursements and the reasonable fees of counsel for each Indemnified Party, whether or not the Indemnified Party shall be designated a party thereto), which may be imposed on, incurred by, or asserted against, any Indemnified Party in any manner relating to or arising out of (i) your access to or use of the Only X LLC Services; (ii) your breach or alleged breach of these Terms; (iii) your violation of Applicable Law; (iv) any action we take pursuant to your written or oral instructions; (v) your other actions or omissions that result in liability to Only X LLC. Notwithstanding the foregoing, Customer shall not have any obligation hereunder to any Indemnified Party to the extent caused by or resulting from the bad faith, willful misconduct or gross negligence of such Indemnified Party. To the extent that the undertaking to indemnify set forth in the preceding sentence may be unenforceable because it is violative of any law or public policy, Customer shall satisfy such undertaking to the maximum extent permitted by Applicable Law. Any liability, obligation, loss, damage, penalty, cost or expense covered by this indemnity shall be paid to each Indemnified Party on demand. The provisions of this Section shall survive the termination of this Agreement."
    },
    {
      id: "disclaimer-damages",
      type: "section",
      title: "17. Disclaimer of Damages; Limitation of Liability",
      subsections: [
        {
          id: "liability-infrastructure",
          title: "17.1.",
          content: "Without limitation of any other provision of these Terms, you hereby agree that neither Only X LLC nor any of its Representatives will have any responsibility or liability whatsoever for any loss or injury sustained by you or any third parties as a result of (i) any equipment or technology or other infrastructure delay, inaccessibility, or malfunctions; (ii) periodic maintenance procedures or repairs that Only X LLC or any of our suppliers or contractors may undertake from time to time; (iii) causes beyond Only X LLC's control or that Only X LLC could not reasonably foresee; (iv) disruptions and temporary or permanent unavailability of underlying blockchain infrastructure; or (v) unavailability of third-party service providers or external partners for any reason. Under no circumstances will Only X LLC or its Representatives have any liability for any such loss or injury caused by any of the foregoing events, including but not limited to any obligation to cover or reimburse any damages or losses caused by such events. You expressly acknowledge that any risk of loss resulting from such events shall be borne by you, and you expressly assume any and all such risks."
        },
        {
          id: "liability-limitation",
          title: "17.2.",
          content: "In no event will Only X LLC, our suppliers and contractors, and Only X LLC's or our suppliers' and contractors' respective stockholders, members, directors, officers, managers, employees, attorneys, agents, representatives, suppliers and contractors (collectively, the 'Risk Limited Parties') be liable for any incidental, indirect, special, punitive, consequential or similar damages or liabilities whatsoever (including damages for loss of fiat, assets, data, information, revenue, opportunities, use, goodwill, profits or other business or financial benefit) arising out of or in connection with the Services (and any of their respective content and functionality), any execution or settlement of a transaction, any performance or non-performance of the Services, your Digital Assets, Perpetual Contracts or any other product, service or other item provided by or on behalf of Only X LLC, whether under contract, tort (including negligence), civil liability statute strict liability breach of warranties or under any other theory of liability and whether or not we have been advised of, knew of or should have known of the possibility of such damages and, notwithstanding any failure of the essential purpose of these Terms or any limited remedy hereunder, nor is Only X LLC in any way responsible for the execution or settlement of transactions between users of the Services."
        },
        {
          id: "liability-cap",
          title: "17.3.",
          content: "Without limitation of any provision of these Terms, in the event that Only X LLC or any related party is found liable under these Terms, the aggregate liability of Only X LLC (together with our equity owners, members, directors, managers, officers, employees, attorneys, agents, representatives, suppliers, or contractors), arising out of or in connection with your use of the Services (and any of their content and functionality), any performance or nonperformance of the Services, your Digital Assets, Perpetual Contracts or any other product, service or other item provided by or on behalf of Only X LLC, whether under contract, tort (including negligence), civil liability, statute, strict liability or other theory of liability, will not exceed the amount of fees paid by you to Only X LLC under these Terms, if any, in the two (2) month period immediately preceding the event giving rise to the claim for liability."
        }
      ]
    },
    {
      id: "dispute-resolution",
      type: "section",
      title: "18. Dispute Resolution and Arbitration",
      subsections: [
        {
          id: "binding-arbitration",
          title: "18.1. Binding Arbitration",
          content: "Any dispute, controversy or claim arising out of or relating to these Terms, including the formation, interpretation, breach or termination thereof, including whether the claims asserted are arbitrable, will be referred to and finally determined by arbitration in accordance with the JAMS International Arbitration Rules. The tribunal will consist of a sole arbitrator. The place of arbitration will be Wilmington, Delaware. The language to be used in the arbitral proceedings will be English. Judgment upon the award rendered by the arbitrator may be entered in any court having jurisdiction thereof."
        },
        {
          id: "jury-trial-waiver",
          title: "18.2. Waiver of Jury Trial",
          content: "YOU AND ONLY X LLC HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and Only X LLC are instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement, except as specified in Section 18.6 below. An arbitrator can award on an individual basis the same damages and relief as a court and must follow this Agreement as a court would. However, there is no judge or jury in arbitration, and court review of an arbitration award is subject to very limited review.",
          type: "warning"
        },
        {
          id: "class-action-waiver",
          title: "18.3. Waiver of Class or Other Non-Individualized Relief",
          content: "ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If a decision is issued stating that applicable law precludes enforcement of any of this subsection's limitations as to a given claim for relief, then the claim must be severed from the arbitration and brought into the State or Federal Courts located in Wilmington, Delaware. All other claims shall be arbitrated.",
          type: "warning"
        },
        {
          id: "opt-out",
          title: "18.4. 30-Day Right to Opt Out",
          content: "You have the right to opt out of the provisions of this Arbitration Agreement by sending written notice of your decision to opt out to: Only X LLC's 300 Colonial Center Parkway Roswell GA 30076, within 30 days after first becoming subject to this Arbitration Agreement. Your notice must include your name and address, your Only X LLC username (if any), and an unequivocal statement that you want to opt out of this Arbitration Agreement. If you opt out of this Arbitration Agreement, all other parts of this Agreement will continue to apply to you. Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may currently have, or may enter in the future, with us."
        },
        {
          id: "severability",
          title: "18.5. Severability",
          content: "If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Arbitration Agreement shall continue in full force and effect."
        },
        {
          id: "survival",
          title: "18.6. Survival of Agreement",
          content: "This Arbitration Agreement will survive the termination of your relationship with Only X LLC."
        },
        {
          id: "modification",
          title: "18.7. Modification",
          content: "Notwithstanding any provision in this Agreement to the contrary, we agree that if Only X LLC makes any future material change to this Arbitration Agreement, you may reject that change within 30 days of such change becoming effective by writing Only X LLC at 300 Colonial Center Parkway Roswell GA 30076"
        }
      ]
    },
    {
      id: "governing-law",
      type: "section",
      title: "19. Governing Law",
      content: "The interpretation and enforcement of these Terms, and any Dispute related to these Terms or the Services, will be governed by and construed and enforced under the laws of the State of Delaware, as applicable, without regard to conflict of law rules or principles (whether of the State of Delaware or any other jurisdiction) that would cause the application of the laws of any other jurisdiction. You agree that we may initiate a proceeding related to the enforcement or validity of our intellectual property rights in any court having jurisdiction. For any other proceeding that is not subject to arbitration under these Terms, the state and federal courts located in Delaware will have exclusive jurisdiction. You waive any objection to venue in any such courts."
    },
    {
      id: "general-information",
      type: "section",
      title: "20. General Information",
      subsections: [
        {
          id: "communications",
          title: "20.1.",
          content: "You consent to receive all communications, agreements, documents, receipts, notices, and disclosures electronically (collectively, our 'Communications') that we provide in connection with these Terms or any Services. You agree that we may provide our Communications to you by posting them on the Site, by emailing them to you at the email address you provide in connection with using the Services, if any, or by Telegram at the username you provided to us during the course of your use of the Services. You should maintain copies of our Communications by printing a paper copy or saving an electronic copy. You may also contact us with questions, complaints, or claims concerning the Services at support@onlyx.io"
        },
        {
          id: "rights-remedies",
          title: "20.2.",
          content: "Any right or remedy of Only X LLC set forth in these Terms is in addition to, and not in lieu of, any other right or remedy whether described in these Terms, under Applicable Law, at law, or in equity. The failure or delay of Only X LLC in exercising any right, power, or privilege under these Terms will not operate as a waiver thereof."
        },
        {
          id: "severability",
          title: "20.3.",
          content: "The invalidity or unenforceability of any of these Terms will not affect the validity or enforceability of any other of these Terms, all of which will remain in full force and effect."
        },
        {
          id: "force-majeure",
          title: "20.4.",
          content: "We will have no responsibility or liability for any failure or delay in performance of any of the Services, or any loss or damage that you may incur, due to any circumstance or event beyond our control, including any flood, extraordinary weather conditions, earthquake, or other act of God, fire, war, insurrection, riot, labor dispute, accident, action of government, communications, power failure, or equipment or software malfunction."
        },
        {
          id: "assignment",
          title: "20.5.",
          content: "You may not assign or transfer any right to use the Services, or any of your rights or obligations under these Terms, without our express prior written consent, including by operation of law or in connection with any change of control. We may assign or transfer any or all of our rights or obligations under these Terms, in whole or in part, with or without notice or obtaining your consent or approval."
        },
        {
          id: "entire-agreement",
          title: "20.6.",
          content: "These Terms contain the entire agreement between you and Only X LLC, and supersede all prior and contemporaneous understandings between the parties with respect to the Services."
        },
        {
          id: "conflicts",
          title: "20.7.",
          content: "In the event of any conflict between these Terms and any other agreement you may have with us, these Terms will control unless such other agreement specifically identifies these Terms and declares that such other agreement supersedes these Terms."
        },
        {
          id: "third-party",
          title: "20.8.",
          content: "You agree that, except as otherwise expressly provided in this Agreement, there are no third-party beneficiaries to the Agreement other than the Indemnified Parties."
        }
      ]
    }












  ]
};

export default termsOfService;
