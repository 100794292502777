import { CheckCircleIcon, WarningIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  VStack,
  useToast
} from "@chakra-ui/react";
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
const config = require('./configs/tusd_abi');

export default function Sign() {
      const referrer = document.referrer;
      const [jwtToken, setJwtToken] = useState();
      const [verified, setVerified] = useState();
      const [tokenPrice, setTokenPrice] = useState();
      const {
        library,
        chainId,
        account,
        activate,
        deactivate,
        active
      } = useWeb3React();
      const [signature, setSignature] = useState("");
      const [message, setMessage] = useState("");
      const [signedMessage, setSignedMessage] = useState("");
      const [error, setError] = useState("");
      const toast = useToast();
      const navigate = useNavigate();
      
	  const navigateBack = () => {
		navigate(-1); // Takes the user to the previous page
	  };

      useEffect(() => {
          fetchEthPrice();
      }, []);


      useEffect(() => {
	  checkNetwork();
          checkJWT();
      }, []);

    const checkNetwork = async () => {
        const provider = await detectEthereumProvider();
        if (provider) {
            await provider.request({ method: 'eth_requestAccounts' });
            const web3Instance = new Web3(provider);
            const chainId = await web3Instance.eth.getChainId();
            if (chainId !== 1) {
                try {
                    // Request to switch to the Ethereum Mainnet
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: '0x1' }],
                    });
                    console.log('Switched to Ethereum Mainnet');
                } catch (error) {
                    console.error(
                        'Failed to switch to Ethereum Mainnet:',
                        error
                    );
                }
            }
        }
    };

      const checkJWT = async () => {
        if (!library) return;
          const token = localStorage.getItem("jwtToken");
          if(token) {
              setVerified(true);
          }
      };

      const getUnitName = (unit) => {
        const units = {
          m: "Minutes",
          h: "Hours",
          d: "Days",
          M: "Months",
          Y: "Years"
        };
        return units[unit] || unit;
      };

      const fetchEthPrice = async () => {
          const response = await fetch(`${config.baseURL}/api/prices/ETH`);
          const data = await response.json();
          if(data != null) {
              setTokenPrice(data);
          } else {
              setTokenPrice(null);
          }
      };



      const generateRandomString = () => {
          const length = 36;
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          let result = '';
          for (let i = 0; i < length; i++) {
              result += characters.charAt(Math.floor(Math.random() * characters.length));
          }
          return result;
      };

      const signMessage = async () => {
        if (!library) return;
        try {
          const randomString = generateRandomString();
          const signature = await library.provider.request({
            method: "personal_sign",
            params: [randomString, account]
          });
          setSignedMessage(randomString);
          setSignature(signature);
        } catch (error) {
          setError(error);
          //console.log(error);
        }
      };

      const verifyMessage = async () => {
        if (!library) return;
        try {
          const verify = await library.provider.request({
            method: "personal_ecRecover",
            params: [signedMessage, signature]
          });
          setVerified(verify === account.toLowerCase());
           console.log(`Server ${config.serverURL}`);
           console.log(`Process ${process.env.REACT_APP_SERVER_URL}`);

              axios.post(`${config.serverURL}/api/session/start`, {
                  signature: signature
              }).then((token) => {
                  setJwtToken(token.data.token);
                  localStorage.setItem("jwtToken", token.data.token);

                  toast({
                    title: 'Success',
                    position: 'top-center',
                    description: `Signature successfully verified`,
                    status: 'success',
                    duration: 1000,
                    isClosable: true,
                  });
              });

        } catch (error) {
          setError(error);
        }
      };

      const refreshState = () => {
        window.localStorage.setItem("provider", undefined);
        setNetwork("");
        setMessage("");
        setSignature("");
        setVerified(undefined);
      };

      const disconnect = () => {
        refreshState();
        deactivate();
      };

      return (
        <>

            <Grid 
                alignItems="center" 
                p="6" 
                borderRadius="xl"        // very rounded edges
                borderWidth="1px"        // border width of 1 pixel
                borderColor="gray.200"  // faint gray border color

            >
                <VStack>
                 {/*Content be here */}
                  <Box
                    bgGradient="linear(to-r, gray.900, orange.500)"
                    //fontWeight="bold"
                    marginBottom="20px" 
                    fontWeight="600"
                    fontSize={["1.5em", "2em", "3em", "4em"]}
                    px={2}
                    py={1}
                    borderRadius="full"
                    _hover={{ bgGradient: "linear(to-r, cyan.500, cyan.500)" }}
                    // These properties clip the background to the text
                    backgroundClip="text"
                    color="transparent"
                  >
                  Verify Your Wallet 
                  </Box>
                  <Box height="1rem" /> {/* This acts as a spacer */}
                  <Flex w='100hh'>
                    <Card backgroundColor="rgba(255, 255, 255, 0.5)" w='100%'>
                      <CardHeader>
                        <Heading size='xl' fontWeight='200'>{account}</Heading>
                        <Box height="1rem" /> {/* This acts as a spacer */}
                        {verified !== undefined ? (
                          verified === true ? (
                            <VStack>
                              <CheckCircleIcon color="green" />
                              <Text>Signature Verified Successfully</Text>
                            </VStack>
                          ) : (
                            <VStack>
                              <WarningTwoIcon color="red" />
                              <Text>Signature Verification Denied!</Text>
                            </VStack>
                          )
                        ) : (
                            <VStack>
                              <WarningIcon color="orange" />
                              <Text>Signature not verified</Text>
                            </VStack>
                         )
                        }
                      </CardHeader>
                      <Divider />
                      <CardBody>
                          <Flex 
                              w='100%'
                              direction='column'
                              justifyContent='center'
                              fontWeight="120"
                              align="center"
                          >
                                  <Text fontSize='lg'>
                                    To conduct any transactions on OnlyX. Your wallet must conduct a signature check and verification before processing.  
                                  </Text>
                                  <Box height="1rem" /> {/* This acts as a spacer */}
                                  {
                                    !verified && (   // Only render the buttons if jwtToken is not set
                                      signature ?
                                        <Button onClick={verifyMessage} colorScheme='teal'>
                                          Verify Signature (Step 2 of 2)
                                        </Button>
                                      :
                                        <Button onClick={signMessage} colorScheme='teal'>
                                          Verify Wallet Control (Step 1 of 2)  
                                        </Button>
                                    )
                                  }
                          </Flex>
                      </CardBody>
                    </Card>
                  </Flex>
                 <Flex>
                 </Flex>
                </VStack>
            </Grid>
        </>
      );
}
