import {
  Center,
  Grid
} from "@chakra-ui/react";

export default function AboutPage() {
      return (
        <>

            <Grid 
                alignItems="center" 
                p="6" 
                w='100%'
                bg="whiteAlpha.900"      // off-white background
                borderRadius="xl"        // very rounded edges
                borderWidth="1px"        // border width of 1 pixel
                borderColor="gray.200"  // faint gray border color

            >
            <Center>
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            one tow  one towone towone towone towone towone towone towone towone towone towone towone towone towone tow;
            </Center>
            </Grid>

        </>
      );
}
