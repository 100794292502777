import React, { useState, useEffect } from "react";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import ProgressBarModal from './ProgressBarModal.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';

const config = require('./configs/tusd_abi.js');
const PORTFOLIO_ADDRESS = config.portfolioAddress;
const PORTFOLIO_ABI = config.portfolioAbi;

export default function BalanceModal() {
  const [amountInvested, setAmountInvested] = useState(0);
  const [amountUSD, setAmountUSD] = useState('');
  const [currentValuation, setCurrentValuation] = useState(0);
  const [balance, setBalance] = useState(0);
  const [networth, setNetworth] = useState(0);
  const [profitLoss, setProfitLoss] = useState(0);
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [contract, setContract] = useState(null);
  const [progress, setProgress] = useState(0);
  const [transactionLink, setTransactionLink] = useState(null);
  const [totalPositionAmount, setTotalPositionAmount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const TUSD_RESERVE = config.TUSDreserveAccount;

  useEffect(() => {
    async function init() {
      const provider = await detectEthereumProvider();
      if (provider) {
        await provider.request({ method: "eth_requestAccounts" });
        const web3Instance = new Web3(provider);
        setWeb3(web3Instance);
        const accounts = await web3Instance.eth.getAccounts();
        setAccounts(accounts);
        const contractInstance = new web3Instance.eth.Contract(PORTFOLIO_ABI, PORTFOLIO_ADDRESS);
        setContract(contractInstance);
		if (accounts.length > 0 && contractInstance) {
			getBalance(contractInstance, accounts[0]);
			getUserRecords(contractInstance, accounts[0]);
		  }
      } else {
        console.error("Please install MetaMask!");
      }
    }
    init();
  }, []);

  useEffect(() => {
	if (contract && accounts.length > 0) {
	  getUserRecords();
	  handleOpenPositions();
	}
  }, [contract, accounts]);

  useEffect(() => {
	const networthValue = (parseFloat(balance) + parseFloat(currentValuation)).toFixed(2);
	setNetworth(networthValue.toString()); // Store as string
}, [balance, currentValuation]);

useEffect(() => {
	const profitLossValue = (parseFloat(amountInvested) - parseFloat(currentValuation)).toFixed(2);
	setProfitLoss(profitLossValue.toString()); // Store as string
}, [amountInvested, currentValuation]);

  const getBalance = async (contractInstance, account) => {
	try {
	  const balance = await contractInstance.methods.getNetBalance(account).call();
	  setBalance(parseFloat(Web3.utils.fromWei(balance.toString(), "ether")).toFixed(2)); 
	} catch (error) {
	  console.error("Error getting balance:", error);
	}
  };
  
  const getUserRecords = async () => {
	if (!contract) {
	  return;
	}
	try {
	  const userAddy = accounts[0];
	  const userRecords = await contract.methods.searchRecordsByAddress(userAddy, 0).call();
	  const totalCurrentValuation = userRecords.reduce((total, record) => {
		const valuation = record.currentValuation || '0';
		return total + parseFloat(Web3.utils.fromWei(valuation.toString(), 'ether'));
	  }, 0);
	  setCurrentValuation(totalCurrentValuation.toFixed(2));
  
	  const totalAmountInvested = userRecords.reduce((total, record) => {
		const invested = record.amountInvested || '0';
		return total + parseFloat(Web3.utils.fromWei(invested.toString(), 'ether'));
	  }, 0);
	  setAmountInvested(totalAmountInvested.toFixed(2));
	} catch (error) {
	  console.error("Error searching records:", error);
	}
  };

  const withdrawAssets = async () => {
	if (!web3) {
		console.log('Web3 is not initialized. Click "Connect Wallet" first.');
		return;
	}

	const accounts = await web3.eth.getAccounts();
	if (accounts.length === 0) {
		console.error("No accounts found. Make sure MetaMask is connected.");
		return;
	}
	if (isNaN(amountUSD) || amountUSD <= 0) {
		console.error("Invalid amount. Please enter a valid number.");
		return;
	}
	const recipientAddress = accounts[0];
	const price = window.tusdPrice;
	const amountInTokens = amountUSD / price;
	console.log(`price ${price} amount tokens ${amountInTokens}`)
	
	try {
		handleWithdraw(recipientAddress, amountInTokens);
	} catch(error) {
		console.error('Error during withdrawal transaction:', error);
	}
};

const handleWithdraw = async (recipient, amount) => {
    if (!recipient || !amount) return;
	if (100 > balance - amount) {
		toast.error("The withdraw amount is over the minimum amount of $100 needed to be in account balance");
	}else {
		setIsModalOpen(true); 
		try {
			const withdrawResponse = await fetch(`${config.serverURL}/api/contract/withdraw`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					address: recipient,
					amount: amount
				}),
			});

			if (!withdrawResponse.ok) {
				throw new Error('Error withdrawing');
			}

			const data = await withdrawResponse.json();
			console.log('Data withdraw', data);

			// Assuming the transaction hash is included in the response data
			const transactionHash = data.hash;
			if (transactionHash) {
				const link = `https://etherscan.io/tx/${transactionHash}`;
				setTransactionLink(link);
				setProgress(30);
				toast.info("Transaction pending...");

				const checkReceipt = async () => {
					try {
						const receipt = await web3.eth.getTransactionReceipt(transactionHash);
						if (receipt) {
							setProgress(50);
							console.log('Transaction receipt:', receipt);

							// Update server with transaction details after receipt confirmation
							fetch(`${config.serverURL}/api/contract/add_record`, {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json',
								},
								body: JSON.stringify({
									token: recipient,
									address: recipient,
									amount: amount,
									direction: '1',
									transactionHash: receipt.transactionHash
								}),
							})
							.then(() => {
								setProgress(100);
								toast.success("Transaction confirmed!");
								setIsModalOpen(false); // Close modal on success
							})
							.catch((error) => {
								console.error("Failed to add record to the server:", error);
								setProgress(0);
								setIsModalOpen(false);
							});
						} else {
							// Poll again after a delay if the receipt is not yet available
							setTimeout(checkReceipt, 2000);
						}
					} catch (error) {
						console.error("Error getting receipt:", error);
					}
				};
				checkReceipt(); // Start polling
			} else {
				throw new Error("Transaction hash not found.");
			}
		} catch (error) {
			console.error("Error during withdrawal:", error);
		}
	}
};

const handleOpenPositions = async () => {
	if (!contract || accounts.length === 0) {
		console.error("Contract not initialized or no accounts available.");
		return;
	}
	try {
		const userAddy = accounts[0];
		const userPositions = await contract.methods.getOpenPositions(userAddy).call();
		const totalOpenPositions = userPositions.reduce((acc, position) => acc + Number(position.amount), 0);

		console.log('Total Amount for Positions', totalOpenPositions);
		setTotalPositionAmount(parseFloat(Web3.utils.fromWei(totalOpenPositions.toString(), 'ether')).toFixed(2));
	} catch (error) {
		console.error("Error searching records:", error);
	}
};

  return (
	<div className="flex flex-col gap-[12px] bg-white rounded-2xl border border-blue-200 p-1 md:p-1 lg:p-1">
		<div className="flex justify-center items-center gap-2 w-full h-[52px] bg-gray-100 rounded-2xl">
			<div className='balance-icon'/>
			<span className="font-bold text-gray-500 text-xl md:text-2xl">My Balance</span>
		</div>

		<div className="flex flex-col w-full px-[1px] md:px-[4px] lg:px-[6px] gap-[12px]">
			<div className="flex flex-col gap-[4px]">
				<span className="text-[12px] md:text-[12px] text-[#6b7280]">
					Account Balance (tUSD)&nbsp;
					<button data-tip="Margin Balance" id="myButton" className="tooltip-icon"></button>
					<ReactTooltip anchorId="myButton" />
				</span>
				<span className="text-[10px] md:text-[12px] font-bold text-[#9ca3af]">
					${balance}
				</span>
			</div>
			<div className="flex flex-col gap-[4px]">
				<span className="text-[10px] md:text-[12px] text-[#6b7280]">
					Open Positions (tUSD)&nbsp;
					<button data-tip="Current Open Positions" id="myButton" className="tooltip-icon"></button>
					<ReactTooltip anchorId="myButton" />
				</span>
				<span className="text-[10px] md:text-[12px] font-bold text-[#fb923c]">
					${totalPositionAmount}
					{/* ${parseFloat(Web3.utils.fromWei(currentValuation.toString(), 'ether')).toFixed(2)} */}

				</span>
			</div>
			<div className="flex flex-col gap-[4px]">
				<span className="text-[10px] md:text-[12px] text-[#6b7280]">
					Networth (tUSD)&nbsp;
					<button data-tip="Total of Account Balance and Open Positions" id="myButton" className="tooltip-icon"></button>
					<ReactTooltip anchorId="myButton"/>
				</span>
				<span className="text-[10px] md:text-[12px] font-bold text-[#1e3a8a]">
					${networth}
					{/* ${parseFloat(Web3.utils.fromWei(networth.toString(), 'ether')).toFixed(2)} */}
					
				</span>
			</div>

			{/* Total Unrealized PnL */}
			<div className="flex flex-col gap-[4px]">
				<span className="text-[10px] md:text-[12px] text-[#6b7280]">
					Total Unrealized PnL (tUSD)&nbsp;
					<button data-tip="Unrealized Profit or Loss" id="myButton" className="tooltip-icon"></button>
					<ReactTooltip anchorId="myButton" />
				</span>
				<div className="flex gap-[6px] items-center">
					<span className={profitLoss >= 0 ? 'profit-icon' : 'loss-icon'} />
					<span
						className={`text-[16px] md:text-[18px] font-bold ${
							profitLoss >= 0 ? 'text-[#10b981]' : 'text-[#ef4444]'
						}`}
					>
						${profitLoss}
					</span>
				</div>
			</div>
			
			<div className="flex flex-col gap-1 items-start w-full">
				<span className="text-xs md:text-sm text-gray-500">Enter Withdraw Amount (tUSD)</span>
				
				<div className="flex items-center gap-2 w-full border border-gray-500 rounded-md p-2">
					<input
					type="number"
					id="amount"
					value={amountUSD}
					onChange={(e) => setAmountUSD(e.target.value)}
					placeholder="Amount"
					className="w-full text-sm text-base "
					/>
				</div>
			</div>
			<button
				className="flex items-center justify-center gap-2 w-full h-[52px] bg-white rounded-2xl border border-gray-500 mt-4 hover:bg-gray-100"
				onClick={withdrawAssets}
			>
				<span className="font-bold text-gray-500 text-sm md:text-base">
					Withdraw
				</span>
				<span className='deposit-icon'/>
			</button>
			<ProgressBarModal
				isOpen={isModalOpen}
				onRequestClose={() => setIsModalOpen(false)}
				progress={progress}
				transactionLink={transactionLink}
			/>
		</div>
	</div>

  );
}


