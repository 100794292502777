import Web3 from 'web3';
import { tUSDAbi } from './configs/tusd_abi.js';
import { USDCAbi } from './configs/tusd_abi.js';
import { portfolioAbi } from './configs/tusd_abi.js';
import { tUSDContractAddress } from './configs/tusd_abi.js';
import { USDCContractAddress } from './configs/tusd_abi.js';
import { portfolioAddress } from './configs/tusd_abi.js';


let selectedAccount;

let tUSDContract;

let USDCContract;

let portfolioContract;

let isInitialized = false;

let web3;

export const init = async () => {
	let provider = window.ethereum;
	if (typeof provider !== 'undefined') {
		provider
			.request({ method: 'eth_requestAccounts' })
			.then((accounts) => {
				selectedAccount = accounts[0];
			})
			.catch((err) => {
				console.log(err);
				return;
			});

		window.ethereum.on('accountsChanged', function (accounts) {
			selectedAccount = accounts[0];
			console.log(`Selected account changed to ${selectedAccount}`);
		});
	}
	web3 = new Web3(provider);
	const networkId = await web3.eth.net.getId();
	tUSDContract = new web3.eth.Contract(
		tUSDAbi,
		tUSDContractAddress
	);
	USDCContract = new web3.eth.Contract(
		USDCAbi,
        USDCContractAddress
	);
	
	portfolioContract = new web3.eth.Contract(
		portfolioAbi,
        portfolioAddress
	);
	isInitialized = true;
};

export const getTUSDBalance = async () => {
	if (!isInitialized) {
		await init();
	}

	return tUSDContract.methods
		.balanceOf(selectedAccount)
		.call()
		.then((balance) => {
			return balance;
		});
};

export const getUSDCBalance = async () => {
	if (!isInitialized) {
		await init();
	}

	return USDCContract.methods
		.balanceOf(selectedAccount)
		.call()
		.then((balance) => {
			return balance;
		});
};

export const getPortfolioCBalance = async () => {
	if (!isInitialized) {
		await init();
	}

	return portfolioContract.methods
		.balanceOf(selectedAccount)
		.call()
		.then((balance) => {
			return balance;
		});
};

export const getEthBalance = async () => {
    if (!isInitialized) {
        await init();
    }
    return new Promise((resolve, reject) => {
        web3.eth.getBalance(selectedAccount, (error, wei) => {
            if (error) {
                reject(error);
            } else {
                const balance = web3.utils.fromWei(wei, 'ether');
                resolve(balance);
            }
        });
    });
};

