import { useEffect, useState } from "react";
import Web3 from 'web3';
import { AddIcon, ExternalLinkIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Select,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
  useToast
  } from "@chakra-ui/react";
import detectEthereumProvider from '@metamask/detect-provider';
//import './portfolio.css'
import 'chart.js/auto';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as TooltipRe, Legend, ResponsiveContainer } from 'recharts';
const config = require('./configs/tusd_abi');


export default function Portfolio() {
  const [jwtToken, setJwtToken] = useState();
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [contract, setContract] = useState(null);
  const [amountUSD, setAmountUSD] = useState('');
  const [lastFetched, setLastFetched] = useState(null);
  const [assetType, setAssetType] = useState('ETH');
  const [totals, setTotals] = useState({});
  const [senderAddress, setSenderAddress] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState({});
  const [data, setData] = useState([]);
  const [records, setRecords] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const toast = useToast();
  const infuraKey = config.infuraKey;
  const TUSD_ADDRESS = config.tUSDContractAddress;
  const TUSD_ABI = config.tUSDAbi;
  const USDC_ADDRESS = config.USDCContractAddress;
  const USDC_ABI = config.USDCAbi;
  const PORTFOLIO_ADDRESS = config.portfolioAddress;
  const PORTFOLIO_ABI = config.portfolioAbi;
  const chainlinkTusdAddress = config.chainlinkTusdAddress;
  const chainlinkEthAddress = config.chainlinkEthAddress;
  const chainlinkUsdcAddress = config.chainlinkUsdcAddress;
  const chainlinkAbi = config.chainlinkAbi;
  const USDC_RESERVE = config.USDCreserveAccount;
  const TUSD_RESERVE = config.TUSDreserveAccount;
  const ETH_RESERVE = config.ETHreserveAccount;

  useEffect(() => {
      fetchJWT();
  }, []);

  useEffect(() => {
      if (web3) {
          //fetchEthPrice();
          fetchPrices();
      }
  }, [web3]);
  
  useEffect(() => {
      async function fetchAccounts() {
          if (window.ethereum) { // Check if MetaMask is installed
              try {
                  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' }); // Request account access
                  if (accounts.length > 0) {
                      setSenderAddress(accounts[0]); // Set the first account as the sender address
                      fetchNetBalanceData(accounts[0]);
                      //fetchNetBalanceByAsset(accounts[0]);
                      // fetchNetBalanceGraph(accounts[0]);
                  }
              } catch (error) {
                  console.error('Error fetching accounts:', error);
              }
          } else {
              console.log('Please install MetaMask!');
          }
      }
      fetchAccounts();
  }, []);

  useEffect(() => {
    if (contract && accounts.length > 0) {
        getPositions();
    }
}, [contract, accounts]);


  const fetchPrices = async () => {
      let tenMinutes = 600000; // milliseconds
      const currentTime = new Date();
      if (lastFetched && (currentTime - lastFetched < tenMinutes)) {
          return;
      }
      const tokens = [
          { name: 'eth', address: chainlinkEthAddress },
          { name: 'tusd', address: chainlinkTusdAddress },
          { name: 'usdc', address: chainlinkUsdcAddress }
      ];
      try {
          const web3 = new Web3(new Web3.providers.HttpProvider(`https://mainnet.infura.io/v3/${infuraKey}`));

          const fetchPromises = tokens.map(async (token) => {
              const priceFeed = new web3.eth.Contract(chainlinkAbi, token.address);
              const roundData = await priceFeed.methods.latestRoundData().call();
              const price = web3.utils.fromWei(roundData.answer, 'ether') * 1e10;
              const setPriceFunctionName = `set${token.name.charAt(0).toUpperCase()}${token.name.slice(1).toLowerCase()}Price`;
              const setPriceFunction = window[setPriceFunctionName];
              if (typeof setPriceFunction === 'function') {
                  setPriceFunction(price);
              } else {
                  console.error(`Function ${setPriceFunctionName} not found on window.`);
              }

              return { token: token.name, price: price };
          });
          const results = await Promise.all(fetchPromises);
          setLastFetched(currentTime);
      } catch (error) {
          // Fallback to cached prices if available
          tokens.forEach(token => {
              const getCachedPrice = `getCached${token.name.charAt(0).toUpperCase() + token.name.slice(1).toLowerCase()}Price`;
              if (typeof window[getCachedPrice] === 'function') {
                  const cachedPrice = window[getCachedPrice]();
              } else {
                  console.log(`No cached price available for ${token.name.toUpperCase()}`);
              }
          });

      }
  };

  const getPriceFromWindow = () => {
      switch(assetType) {
          case 'ETH': return window.ethPrice;
          case 'TUSD': return window.tusdPrice;
          case 'USDC': return window.usdcPrice;
          default: return 0;
      }
  };

  const handleAssetTypeChange = (e) => {
      setAssetType(e.target.value);
      
  };

  window.setEthPrice = (price) => {
      window.ethPrice = price;
  };
  
  window.setTusdPrice = (price) => {
      window.tusdPrice = price;
  };
  
  window.setUsdcPrice = (price) => {
      window.usdcPrice = price;
  };

  const fetchJWT = () => {
      const token = localStorage.getItem("jwtToken");
      if(!token) {
          window.location.href = '/sign';
      } else {
          setJwtToken(token);
          initWeb3();
      }
  };

  const initWeb3 = async () => {
      const provider = await detectEthereumProvider();
      if (provider) {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const web3Instance = new Web3(provider);
          setWeb3(web3Instance);
          const accounts = await web3Instance.eth.getAccounts();
          setAccounts(accounts);
          const contractInstance = new web3Instance.eth.Contract(PORTFOLIO_ABI, PORTFOLIO_ADDRESS);
          setContract(contractInstance);
      } else {
          console.error("Please install MetaMask!");
      }
  };

  const sendAssets = async () => {
      if (!web3) {
          console.log('Web3 is not initialized. Click "Connect Wallet" first.');
          return;
      }

      const accounts = await web3.eth.getAccounts();
      if (accounts.length === 0) {
          console.error("No accounts found. Make sure MetaMask is connected.");
          return;
      }

      const numericAmountUSD = parseFloat(amountUSD);
      if (isNaN(numericAmountUSD) || numericAmountUSD <= 0) {
          console.error("Invalid amount. Please enter a valid number.");
          return;
      }
      
      let amountToSend;
      let amountInTokens;

      try {
          if (assetType === 'ETH') {
              if (!window.ethPrice) {
                  console.error("ETH price not available. Cannot proceed with transaction.");
                  return;
              }
              //amountToSend = web3.utils.toWei((amountUSD / window.ethPrice).toFixed(18), 'ether');
              amountInTokens = amountUSD / window.ethPrice;
              amountToSend = web3.utils.toWei((amountUSD / window.ethPrice).toFixed(18), 'ether');
          }else if (assetType === 'TUSD') {
              const tokenDecimals = 18;
              amountInTokens = amountUSD / window.tusdPrice;  // This assumes you have fetched the correct tusdPrice
              const amountCovert = (amountInTokens* Math.pow(10, tokenDecimals)).toLocaleString('fullwide', {useGrouping:false});
              amountToSend = web3.utils.toBN(amountCovert).toString();

          } else if (assetType === 'USDC') {
              const tokenDecimals = 18;
              amountInTokens = amountUSD / window.usdcPrice;  // This assumes you have fetched the correct tusdPrice
              const amountCovert = (amountInTokens* Math.pow(10, tokenDecimals)).toLocaleString('fullwide', {useGrouping:false});
              amountToSend = web3.utils.toBN(amountCovert).toString();
          }

          const nonce = await web3.eth.getTransactionCount(senderAddress);
          console.log(`Current nonce: ${nonce}`);

          switch (assetType) {
              case 'ETH':
                  console.log('ETH Values ', senderAddress, ETH_RESERVE, amountToSend, amountInTokens);
                  try {
                      web3.eth.sendTransaction({
                          from: senderAddress,
                          to: ETH_RESERVE,
                          value: amountToSend,
                          gas: 21000,
                          nonce: nonce
                      })
                      .on('transactionHash', hash => {
                          console.log(`Transaction hash: ${hash}`);
                      })
                      .on('receipt', async receipt => {
                          try {
                              await fetch(`${config.serverURL}/api/contract/add_record`, {
                                  method: 'POST',
                                  headers: {
                                      'Content-Type': 'application/json',
                                  },
                                  body: JSON.stringify({
                                      token: ETH_RESERVE,
                                      address: senderAddress,
                                      amount: amountToSend,
                                      direction: '0',
                                      transactionHash: receipt.transactionHash,
                                      assetType: assetType
                                  }),
                              });
  
                              setAmountUSD("");
                              logDeposit(senderAddress, ETH_RESERVE, amountInTokens, assetType);
                              toast({
                                  title: 'Success',
                                  position: 'top-center',
                                  description: (
                                      <>
                                          Request processed successfully.
                                          <p>
                                              <Link target="_blank" href={`https://etherscan.io/tx/${receipt.transactionHash}`}>
                                                  View Transaction
                                              </Link>
                                          </p>
                                      </>
                                  ),
                                  status: 'success',
                                  duration: 9000,
                                  isClosable: true,
                              });
                          } catch (error) {
                              console.error('Failed to add record to the server:', error);
                              toast({
                                  title: 'Error',
                                  position: 'top-center',
                                  description: 'Failed to add record to the server.',
                                  status: 'error',
                                  duration: 9000,
                                  isClosable: true,
                              });
                          }
                      })
                      .on('error', error => {
                          console.error(`Transaction failed: ${error}`);
                          toast({
                              title: 'Transaction Failed',
                              position: 'top-center',
                              description: `An error occurred: ${error.message}`,
                              status: 'error',
                              duration: 9000,
                              isClosable: true,
                          });
                      });
                  } catch (error) {
                      console.error('Error sending ETH transaction:', error);
                  }
                  break;
              case 'TUSD':
                  sendToken(TUSD_ADDRESS, TUSD_ABI, amountToSend, senderAddress, assetType, amountInTokens);
                  break;
              case 'USDC':
                  sendToken(USDC_ADDRESS, USDC_ABI, amountToSend, senderAddress, assetType, amountInTokens);
                  break;
              default:
                  console.log('Asset type not supported');
          }
              } catch(error) {
                  console.error('Error converting or sending transaction:', error);
              } finally{
              fetchNetBalanceData(accounts[0]);
              }
  };

  const sendToken = async (tokenAddress, tokenABI, amountToSend, senderAddress, assetType, amountInTokens ) => {
      const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
      setIsLoading(true);
      
      console.log(`Send amount ${amountToSend}`);
      
      await tokenContract.methods.transfer(PORTFOLIO_ADDRESS, amountToSend).send({ from: senderAddress })
          .on('transactionHash', hash => {
              console.log(`Transaction hash: ${hash}`);
          })
          .on('receipt', async receipt => {
          console.log("Response " + tokenAddress + ' ' + senderAddress + ' ' + amountToSend + ' ' + receipt.transactionHash);    
              try {
                  await fetch(`${config.serverURL}/api/contract/add_record`, {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                          token: tokenAddress,
                          address: senderAddress,
                          amount: amountToSend,
                          direction: '0',
                          transactionHash: receipt.transactionHash,
                          assetType : assetType
                      }),
                  })
                  .then(response => {
                      if (!response.ok) {
                          throw new Error(`Error adding record: ${response.statusText}`);
                      }
                      logDeposit(senderAddress, tokenAddress, amountInTokens, assetType);
                      console.log('Response', response);
                      return response.json();
                  })
                  .then(data => {
                      console.log('Data check ', data);
                      setAmountUSD("");
                      toast({
                          title: 'Success',
                          position: 'top-center',
                          description: (
                              <>
                                  Request processed successfully.
                                  <p>
                                  <Link target="_blank" href={`https://etherscan.io/tx/${data.hash}`}>
                                      View Transaction
                                  </Link>
                                  </p>
                              </>
                          ),
                          status: 'success',
                          duration: 9000,
                          isClosable: true,
                      });
                  })
                  .catch(error => console.error('Error logging transaction:', error));
              } catch (error) {
                  console.error(`Error adding record: ${error.message}`);
              }
              console.log('Transaction receipt',receipt);
              fetchNetBalanceData(accounts[0]);
              setIsLoading(false);
          })
          .on('error', error => {
              console.error(error);
              setIsLoading(false);
          });
  };
 
  const fetchNetBalanceData = async (userAddress) => {
      if (!userAddress) {
          console.error('User address is undefined or not set.');
          return;
      }
      const urls = {
          balance: `${config.serverURL}/api/user/net-balance?user=${userAddress}`,
          graph: `${config.serverURL}/api/user/graph?user=${userAddress}`
      };
  
      setIsLoading(true);
  
      try {
          const responses = await Promise.all([
              fetch(urls.balance).then(res => res.ok ? res.json() : Promise.reject(`HTTP error! Status: ${res.status}`)),
              fetch(urls.graph).then(res => res.ok ? res.json() : Promise.reject(`HTTP error! Status: ${res.status}`))
          ]);
  
          const [balanceData, graphData] = responses;
  
          // Handle balance data
          if (balanceData && balanceData.length > 0) {
              setTotals(balanceData);
          } else {
              console.log('No balance data found.');
          }
          // Handle graph data
          if (graphData && graphData.length > 0) {
              let labels = graphData.map(item => new Date(item.transaction_date).toLocaleDateString("en-US"));
              let data = graphData.map(item => item.cumulative_balance);

              const minValue = Math.min(...data);
              const maxValue = Math.max(...data);

              setChartData({
                  labels: labels,
                  datasets: [{
                      data: data,
                      borderColor: '#8884d8',
                      fill: true,
                      minValue: minValue,
                      maxValue: maxValue
                  }]
              });
          } else if (graphData === undefined) {
              console.log('graph data undefined')
          }
           else {
              console.log('No transactions found for this user.');
              setChartData(null);
          }
      } catch (error) {
          console.error('Error fetching net balance data:', error);
      } finally {
          setIsLoading(false);
      }
  };
  
  
  const logDeposit = async (sender, recipient, amount, assetType) => {
          await fetch(`${config.serverURL}/api/deposit`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  sender: sender,
                  recipient: recipient,
                  amount: amount,
                  assetType: assetType
              }),
          })
          .then(response => {
              if (!response.ok) {
                  throw new Error('Failed to log deposit');
              }
              return response.json();
          })
          .then(data => {
              console.log('Transaction logged:', data);
              setAmountUSD("");
          })
          .catch(error => console.error('Error logging transaction:', error));
  };

  const logWithdraw = async (recipient, amount, assetType) => {
      await fetch(`${config.serverURL}/api/withdrawal`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              recipient: recipient,
              amount: amount,
              assetType: assetType
          }),
      })
      .then(response => {
          if (!response.ok) {
              throw new Error('Failed to log withdraw');
          }
          return response.json();
      })
      .then(data => {
          console.log('Transaction logged:', data);
      })
      .catch(error => console.error('Error logging transaction:', error));
  };
  
  const withdrawAssets = async () => {
      if (!web3) {
          console.log('Web3 is not initialized. Click "Connect Wallet" first.');
          return;
      }
  
      const accounts = await web3.eth.getAccounts();
      if (accounts.length === 0) {
          console.error("No accounts found. Make sure MetaMask is connected.");
          return;
      }
      const recipientAddress = accounts[0];  // The account receiving the assets
  
      const numericAmountUSD = parseFloat(amountUSD);
      if (isNaN(numericAmountUSD) || numericAmountUSD <= 0) {
          console.error("Invalid amount. Please enter a valid number.");
          return;
      }
      
      let amountToWithdraw;
      let amountInTokens;
  
      try {
          // Assuming you have an equivalent price fetch logic as in your deposits
          if (assetType === 'ETH') {
              if (!window.ethPrice) {
                  console.error("ETH price not available. Cannot proceed with transaction.");
                  return;
              }
              //amountToSend = web3.utils.toWei((amountUSD / window.ethPrice).toFixed(18), 'ether');
              amountInTokens = amountUSD / window.ethPrice;
              amountToWithdraw = web3.utils.toWei((amountUSD / window.ethPrice).toFixed(18), 'ether');
          } else if (assetType === 'TUSD') {
              const tokenDecimals = 18;  // Adjust based on actual token decimals for TUSD
              const price = window.tusdPrice;
              amountInTokens = amountUSD / price;
              amountToWithdraw = web3.utils.toBN((amountInTokens * Math.pow(10, tokenDecimals)).toFixed(0)).toString();
          } else if (assetType === 'USDC') {
              const tokenDecimals = 18;  // Adjust based on actual token decimals for USDC
              const price = window.usdcPrice;
              amountInTokens = amountUSD / price;
              amountToWithdraw = web3.utils.toBN((amountInTokens * Math.pow(10, tokenDecimals)).toFixed(0)).toString();
          }    
          
          const nonce = await web3.eth.getTransactionCount(senderAddress);
          console.log(`Current nonce: ${nonce}`);
  
          switch (assetType) {
              case 'ETH':
                  try {
                  handleWithdraw(recipientAddress, amountToWithdraw, assetType, amountInTokens);
                  } catch (error) {
                      console.error('Error sending ETH transaction:', error);
                  }
                  break;
              case 'TUSD':
                  handleWithdraw(recipientAddress, amountToWithdraw, assetType, amountInTokens);
                  break;
              case 'USDC':
                  handleWithdraw(recipientAddress, amountToWithdraw, assetType, amountInTokens);
                  break;
              default:
                  console.log('Asset type not supported');
          }
          
      } catch(error) {
          console.error('Error during withdrawal transaction:', error);
      } finally{
          fetchNetBalanceData(accounts[0]);
        }
  };

  const handleWithdraw = async (recipient, amount, assetType, amountInTokens) => {
      if (!recipient || !amount) return;
      const accounts = await web3.eth.getAccounts();
      console.log('Accounts', accounts[0]);
      setIsLoading(true);
      try {
          const withdrawResponse = await fetch(`${config.serverURL}/api/contract/withdraw`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  address: recipient,
                  amount: amount,
                  assetType: assetType
              }),
          });

          if (!withdrawResponse.ok) {
              throw new Error('Error withdrawing');
          }
  
          const data = await withdrawResponse.json();
        
          console.log('Data withdraw', data);

          console.log('amount in tokens ' + amountInTokens + ' ' + amount);
          logWithdraw(recipient, amountInTokens, assetType);
          toast({
              title: 'Success',
              position: 'top-center',
              description: (
                  <>
                      Withdrawal processed successfully.
                      <p>
                      <Link target="_blank" href={`https://etherscan.io/tx/${data.hash}`}>
                          View Transaction
                      </Link>
                      </p>
                  </>
              ),
              status: 'success',
              duration: 9000,
              isClosable: true,
          });

          
      } catch (error) {
          console.error('Error during withdrawal:', error);
          toast({
              title: 'Error',
              description: `Failed to process withdrawal: ${error.message}`,
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top-center'
          });
          setIsLoading(false);
      }finally {
          fetchNetBalanceData(accounts[0]);
          setIsLoading(false); 
      }
  };

  const getPositions = async () => {
    if (!contract || accounts.length === 0) {
        console.error("Contract not initialized or no accounts available.");
        return;
    }
    try {
        const toRecords = await contract.methods.getAllRecords().call();
        const userAddress = accounts[0].toLowerCase();
        const filteredRecords = toRecords.filter(record => 
            record.addr.toLowerCase() === userAddress || 
            record.token.toLowerCase() === userAddress
        );
        setRecords(filteredRecords);

        let totalPosition = 0;

        const positionDataArray = filteredRecords.map(record => {
            if (record.transactionHash && !record.transactionHash.startsWith('0x')) {
                const amountInWei = record.amount; 
                const amountInEther = parseFloat(web3.utils.fromWei(amountInWei, 'ether')); 
                
                if (record.direction === '0') { 
                    // Deposit (Open Position)
                    totalPosition += amountInEther;
                } else if (record.direction === '1') { 
                    // Withdrawal (Close Position)
                    totalPosition -= amountInEther;
                }
            }
            const date = new Date(record.timestamp * 1000).toLocaleDateString("en-US")
            return {
                date: date,
                position: totalPosition.toFixed(2),
            };
        });

        setPositionData(positionDataArray);

    } catch (error) {
        console.error("Error searching records:", error);
        }
    };

    useEffect(() => {
        if (chartData && chartData.labels && chartData.datasets && positionData.length > 0) {
            let combinedData = [];
    
            let cumulativePosition = 0;
    
            chartData.labels.forEach((label, index) => {
                const positionEntry = positionData.find(entry => entry.date === label);
    
                if (positionEntry) {
                    cumulativePosition = parseFloat(positionEntry.position);
                }
    
                combinedData.push({
                    date: label,
                    netBalance: chartData.datasets[0].data[index],
                    totalPosition: cumulativePosition
                });
            });
    
            positionData.forEach(entry => {
                if (!combinedData.find(data => data.date === entry.date)) {
                    cumulativePosition = parseFloat(entry.position);
                    combinedData.push({
                        date: entry.date,
                        netBalance: null,
                        totalPosition: cumulativePosition
                    });
                }
            });
    
            combinedData.sort((a, b) => new Date(a.date) - new Date(b.date));
    
            setCombinedData(combinedData);
        }
    }, [chartData, positionData]);

    const latestPosition = positionData.length > 0 ? positionData[positionData.length - 1].position : 'N/A';

  return (
      <div className="Portfolio-container">
          {isLoading && (
              <Box position="fixed" top="0" left="0" width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center" bg="rgba(0, 0, 0, 0.5)">
                  <Spinner size="xl" color="white" />
              </Box>
          )}
          <div className="Portfolio-header">
              <h2>Portfolio</h2>
          </div>
          <div className="Portfolio-content">
              <div className="form-section">
                  <div className="input-group">
                      <label htmlFor="amount">Amount</label>
                      <input type="number" id="amount" value={amountUSD} onChange={e => setAmountUSD(e.target.value)} placeholder="Amount"/>
                      
                  </div>
                  <div className="input-group">
                      <label htmlFor="asset">Asset</label>
                      {/* <select id="asset" value={assetType} onChange={e => setAssetType(e.target.value)}> */}
                      <select id="asset" value={assetType} onChange={handleAssetTypeChange}>
                          <option value="ETH">Ethereum (ETH)</option>
                          <option value="TUSD">TrueUSD (TUSD)</option>
                          <option value="USDC">USD Coin (USDC)</option>
                      </select>
                      <p>Current Price: ${getPriceFromWindow() ? getPriceFromWindow().toFixed(5) : 'Loading...'}</p>

                  </div>
                  <button className="btn primary" onClick={withdrawAssets}>Withdraw</button>
                  <button className="btn secondary" onClick={sendAssets}>Deposit</button>
              {isLoading ? (
                  <p>Loading...</p>
              ) : error ? (
                  <p>{error}</p>
              ) : (
                  <div>
                      <h3>Balance by Asset</h3>
                      {totals.length > 0 ? (
                          <ul>
                              {totals.map((item, index) => (
                                  <li style={{ listStyleType: 'none' }} key={index}>
                                      {item.asset_type}: {item.net_balance.toLocaleString()} USD
                                  </li>
                              ))}
                              <li style={{ listStyleType: 'none' }}>Current Positions: {latestPosition} USD</li>
                          </ul>
                      ) : (
                          <p>No transactions found for this user.</p>
                      )}
                  </div>
              )}
          </div>
      <div className="data-section">
          <div className="graph-container">
          <Grid
              alignItems="center"
              p="6"
              bg="whiteAlpha.900"      // off-white background
              borderRadius="xl"        // very rounded edges
              borderWidth="1px"        // border width of 1 pixel
              borderColor="gray.200"  // faint gray border color
              w="full"                 // full width
              minW="900px" // This forces the Grid to at least be as wide as the viewport
          >
           <VStack spacing={4} w="full">
                <Text fontSize="xl" fontWeight="bold" mb="4">
                    Portfolio
                </Text>
                {isLoading ? (
                    <Spinner size="xl" />
                ) : combinedData.length > 0 ? (
                    <ResponsiveContainer width="100%" height={500}>
                        <LineChart data={combinedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" height={70} tick={{ fill: 'gray', fontSize: 12 }} />
                            <YAxis 
                                label={{ value: 'Values', angle: -90, position: 'insideLeft' }}
                                tickFormatter={tick => tick.toFixed(2)}
                            />
                            <Legend />
                            <TooltipRe />
                            <Line type="monotone" dataKey="netBalance" name="Net Balance" stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="totalPosition" name="Total Position" stroke="#82ca9d" activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>
                ) : (
                    <Text>No transactions found for this user.</Text>
                )}
            </VStack>

          </Grid>

          </div>   
      </div>
  </div>
</div>

  );
};
